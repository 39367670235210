angular
    .module('quattro.core.alert.service', [])
    .factory('alertService', ['$q', function ($q) {

        var alerts = [];

        return {
            list: alerts,
            // @type string
            // @text string, or promise w/ resolves w/ text
            add: function alertServiceAdd(type, text) {
                // hotfix: hide positive messages. THIS IS ALERT!
                if (type === 'success') {
                    return; // early exit
                }
                $q.when(text).then(function (text) {
                    alerts.push({
                        type: type,
                        text: text,
                    });
                });
            },
            remove: function alertServiceRemove(index) {
                alerts.splice(index, 1);
            },
            clear: function alertServiceClear() {
                alerts.length = 0;
            }
        };
    }]);
