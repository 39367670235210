angular.module('quattro.core.user.sessionstorage', [])

    .factory('quattroSessionStorage', ['$window', function ($window) {

        // where session data is stored.
        // our sessions span over multiple tabs, thus we store in `localStorage`.

        var storage = $window.localStorage;

        return {
            unset: function (key) {
                delete storage[key];
            },
            set: function (key, value) {
                storage[key] = value;
            },
            get: function (key, defaultValue) {
                return storage[key] || defaultValue;
            },
            setObject: function (key, value) {
                storage[key] = JSON.stringify(value);
            },
            getObject: function (key) {
                try {
                    return JSON.parse(storage[key]);
                } catch (e) {
                    return undefined;
                }
            }
        };
    }]);
