angular.module('quattro.account.create', [
    'quattro.core.country-picker',
    'quattro.core.hpls_api_1',
    'quattro.core.password.service',
])
    .controller('CreateAccountCtrl', function ($scope, passwordService, api, alertService, userService, $location, $log, $q, Account, account_anrede, invoice_types, payment_types, viewHelper, jpb_default_fees) {

        $scope.anreden = account_anrede;
        $scope.rechnungsarten = invoice_types;
        $scope.zahlungsarten = payment_types;
        $scope.jpb_default_fees = jpb_default_fees;

        var d = new Date();
        var adminMemoDefault = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + userService.get('userName');

        var model = new Account({}, 'create');
        $scope.data = model.data;


        $scope.$watchGroup(['data.address_name_vor', 'data.address_name_nach', 'data.firma'], function (newValues, oldValues, scope) {

            // chars not allowed in account name
            var reNotAllowed = /[^a-z0-9\.\_\-]/g;

            // chars not allowed at beginning of account name
            var reNotAllowedFirst = /^[^a-z0-9]*/;

            // unicode range for Combining Diacritical Marks (http://www.unicode.org/charts/PDF/U0300.pdf)
            var reCombining = /[\u0300-\u036F]/g;

            // replace map for not allowed characters.
            // note: once all browsers support `str.normalize` (see below) you
            // can remove diacritical chars from this list.
            var replaceChars = {
                'ß': 'ss',
                'ñ': 'n',
                'ä': 'a',
                'ö': 'o',
                'ü': 'u',
                'à': 'a',
                'è': 'e',
                'ì': 'i',
                'ò': 'o',
                'ù': 'u',
                'á': 'a',
                'é': 'e',
                'í': 'i',
                'ó': 'o',
                'ú': 'u',
                'â': 'a',
                'ê': 'e',
                'î': 'i',
                'ô': 'o',
                'û': 'u',
            };

            function sanitize(str) {
                str = str || '';
                str = str.toLowerCase();
                if (str.normalize) {
                    // remove diacritical marks
                    // works only in "newer" browsers, see
                    str = str.normalize('NFKD').replace(reCombining, '');
                }
                str = str.replace(reNotAllowed, function (x) {
                    return replaceChars[x] || x;
                });
                str = str.replace(reNotAllowedFirst, '');
                str = str.replace(reNotAllowed, '');
                // remove trailing dots, ref #VQ-670
                str = str.replace(/\.*$/, '');
                return str;
            }

            function getAccountName(values) {
                var vorname = sanitize(values[0]);
                var nachname = sanitize(values[1]);
                var firma = sanitize(values[2]);
                var accountName = '';
                if (firma) {
                    accountName = firma;
                } else if (vorname && nachname) {
                    accountName = vorname + '.' + nachname;
                }
                return accountName.toLowerCase();
            }

            var newName = getAccountName(newValues);
            // FIXME: prevent overwriting when changed manually
            scope.data.account = newName;
        });


        $scope.$watch('data.account', function (newValue, oldValue, scope) {
            if ($scope.data.account !== undefined) {
                api.single('validate', {
                    'account': $scope.data.account
                })
                    .then(function (result) {
                        $scope.data.ftpdir = '/srv/www/htdocs/' + $scope.data.account + '/./htdocs/';
                        $scope.data.maildir = '/mail/jpberlin.de/' + $scope.data.account + '/';
                        $scope.data.email = $scope.data.account + '@jpberlin.de';
                    });
            } else {
                $scope.data.ftpdir = '/srv/www/htdocs/?/./';
                $scope.data.maildir = '/mail/jpberlin.de/?/';
                $scope.data.email = '?@jpberlin.de';
            }
        });


        $scope.$watchGroup(['data.memo_mail', 'data.memo_otrs'], function (newValues, oldValues, scope) {
            scope.data.admin_memo = adminMemoDefault;
            if (newValues[0])
                scope.data.admin_memo += '\nOwner: ' + newValues[0];
            if (newValues[1])
                scope.data.admin_memo += '\n' + newValues[1];
        })


        $scope.generatePassword = function () {
            passwordService
                .createValid()
                .then(function (result) {
                    $scope.data.password = result;
                });
        };

        $scope.cancel = function () {
            $location.path('/');
        };

        $scope.save = function () {

            if (!viewHelper.formIsValid()) {
                return;
            }

            var promise = model.create();
            promise.then(function () {
                alertService.add('success', 'Account wurde angelegt.');
                $location.path('/account=' + model.data.account);
            }, function () {
                alertService.add('error', 'Account konnte nicht angelegt werden.');
            });

        };
    });
