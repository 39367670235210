angular
    .module('quattro.email.modal_add', [
        'quattro.model',
        'quattro.email.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Mail', 'myModalService', function ($rootScope, Mail, myModalService) {

        $rootScope.$on('vq.mail.add', function (event, mail, callback) {

            var model = new Mail(mail, 'create');
            var allow_catchall = mail.allow_catchall;

            myModalService.showModal({
                templateUrl: 'jpb/email/modal_add.html',
                controller: 'ModalAddEmailController',
                inputs: {
                    model: model,
                    allow_catchall: allow_catchall,
                },
            }).then(function (modal) {
                modal.close.then(function () {
                    callback && callback();
                });
            });
        });
    }])
    .controller('ModalAddEmailController', function ($scope, close, model, allow_catchall, alertService) {

        $scope.allow_catchall = allow_catchall;

        $scope.data = model.data;


        $scope.cancel = close;

        $scope.save = function () {

            if (model.data.catchall) {

                if (model.data.forwards.length === 0) {
                    alertService.add('error', 'Sie muessen einen Forward angeben.');
                    return;
                }

                // redundant stuff to please api
                model.data.mail = '@' + model.data.domain,
                    model.data.inboxsave = false;

            } else {

                if (model.data.forwards.length) {
                } else {
                    model.data.inboxsave = true;
                }

            }

            var promise = model.create();

            promise.then(function () {
                alertService.add('success', 'Mail wurde angelegt.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Mail konnte nicht angelegt werden.');
            });
        };
    });
