angular
    .module('quattro.core.password.service', [
        'quattro.core.generatePassword',
        'quattro.core.hpls_api_1',
    ])
    .factory('passwordService', function ($rootElement, $q, api, generatePassword) {

        var app = $rootElement.attr('ng-app');


        function validatePassword(password) {
            switch (app) {
                case 'quattro.bmbo':
                    return api.single({
                        method: 'utils.validator',
                        params: {
                            value: password,
                            type: 'pass',
                        },
                    });
                case 'quattro.jpb':
                    return api.single({
                        method: 'validate',
                        params: {
                            password: password,
                        },
                    });
            }
            return $q.when();
        }


        // creates password.
        // tries to validate against api and return only valid passwords.
        // returns promise
        function createValid(hops) {

            if (hops === undefined) {
                hops = 5;
            }

            var pw = generatePassword();
            return validatePassword(pw)
                .then(function () {
                    return pw;
                }, function () {
                    return (hops === 0) ? '' : createValid(hops - 1);
                });
        }


        return {
            createValid: createValid,
        };
    });
