angular.module('quattro.index', [])
    .controller('IndexController', function ($location, userService) {

        var defaultRoute = '';

        var userName = userService.get('userName');
        var userLevel = userService.get('userLevel');
        var index, domain, email, ftpuser;

        if (userLevel === 'admin') {
            defaultRoute = '/dashboard';
        }

        if (userLevel === 'account') {
            defaultRoute = '/dashboard';
        }

        if (userLevel === 'domain') {
            defaultRoute = '/domain=' + userName;
        }

        if (userLevel === 'mail') {
            index = userName.indexOf("@");
            domain = userName.slice((index + 1), userName.length);
            email = userName.slice(0, index);
            defaultRoute = '/domain=' + domain + '/email=' + email;
        }

        if (userLevel === 'ftp') {
            index = userName.indexOf("_");
            //ftpuser = userName.slice((index + 1), userName.length);
            domain = userName.slice(0, index);
            defaultRoute = '/domain=' + domain + '/ftp=' + userName;
        }


        $location.path(defaultRoute || '/login');
        $location.replace();
    });
