angular
    .module('quattro.mailinglist.edit', [
        'ngRoute',
        'quattro.model',
        'quattro.mailinglist.form',
        'quattro.core.alert.service',
    ])
    .controller('EditMailinglistCtrl', function ($anchorScroll, $scope, alertService, $location, $routeParams, Mailinglist, $window) {

        var account = $routeParams.account;
        var mailinglist = $routeParams.mailinglist;
        var section = $routeParams.section;

        $window.setTimeout(function () {
            $anchorScroll(section);
        }, 1);

        var model = new Mailinglist({
            account: account,
            mailinglist: mailinglist
        });
        model.get();

        $scope.data = model.data;
        $scope.account = account;

        $scope.cancel = function () {
            $location.path('/account=' + account + '/mailinglist=' + mailinglist);
        };

        $scope.save = function () {
            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Mailinglist ' + mailinglist + ' wurde gespeichert.');
                $location.path('/account=' + account + '/mailinglist=' + mailinglist);
            }, function (reason) {
                alertService.add('error', 'Mailinglist ' + mailinglist + ' konnte nicht vollständig gespeichert werden.');
            });
        };
    });
