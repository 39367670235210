angular.module('quattro.jpb.model.ftp', [
    'quattro.core.model.prototype',
])
    .factory('Ftp', function ($log, api, Model) {

        // clarify internal naming here
        function getDefaultData() {
            return {
                // primary key
                domain: undefined,
                ftp: undefined,

                type: undefined,
                dir: undefined,
                homedir: undefined,
                memo: undefined,
                password: undefined,

                // admin stuff
                pass_clear: undefined,
            };
        }


        var authorization = {
            read: 'all',
            create: ['admin', 'account', 'domain'],
            update: {
                default: ['admin', 'account', 'domain', 'ftp'],
                attributes: {
                    pass_clear: ['admin'],
                    dir: ['admin', 'account', 'domain'],
                }
            },
            remove: ['admin', 'account', 'domain'],
        };


        var scenarios = {
            read: [
                {
                    method: 'q.domain.ftp.get',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'ftp': {
                            primary_key: true,
                        },
                    },
                    results: {
                        type: null,
                        dir: null,
                        homedir: null,
                        memo: null,
                        pass_clear: null,
                    }
                }
            ],
            create: [
                {
                    method: 'q.domain.ftp.add',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'ftp': true,
                        'dir': null,
                        'password': true,
                        'memo': null,
                    },
                }
            ],
            update: [
                {
                    method: 'q.domain.ftp.set',
                    params: {
                        'ftp': {
                            primary_key: true,
                        },
                        'pass_clear': {
                            undefined_if_empty: true,
                        },
                    },
                },
                {
                    method: 'q.domain.ftp.dir.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'ftp': {
                            primary_key: true,
                        },
                        'dir': null,
                    },
                },
                {
                    method: 'q.domain.ftp.memo.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'ftp': {
                            primary_key: true,
                        },
                        'memo': null,
                    },
                },
                {
                    method: 'q.domain.ftp.password.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'ftp': {
                            primary_key: true,
                        },
                        'password': {
                            undefined_if_empty: true,
                        },
                    },
                },
            ],
            remove: [
                {
                    method: 'q.domain.ftp.del',
                    params: {
                        domain: {
                            primary_key: true,
                        },
                        ftp: {
                            primary_key: true,
                        },
                    },
                },
            ],
        };


        function query(domain) {
            var promise = api.single('q.domain.ftp.list', {
                domain: domain,
            });
            promise.then(null, function (error) {
                $log.error('q.domain.ftp.list', error.code, error.message);
            });
            return promise;
        }


        function Ftp(config, scenario) {
            this.scenario = scenario;
            var data = scenario === 'create' ? getDefaultData() : {};
            this.data = angular.merge(data, config);
            this.data.$validate = this.validate.bind(this);
        }

        // static
        Ftp.query = query;

        Ftp.prototype = angular.merge(new Model(), {
            scenarios: scenarios,
            authorization: authorization,
        });

        return Ftp;
    });
