angular
    .module('quattro.email.modal_edit', [
        'quattro.model',
        'quattro.email.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Mail', 'myModalService', function ($rootScope, Mail, myModalService) {

        $rootScope.$on('vq.mail.edit', function (event, mail, callback) {

            // reflect modal state on mail's scope
            mail.$active = true;

            var model = new Mail(mail);
            model.get();

            myModalService.showModal({
                templateUrl: 'jpb/email/modal_edit.html',
                controller: 'ModalEditEmailController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    mail.$active = false;
                    callback && callback();
                });
            });

        });
    }])
    .controller('ModalEditEmailController', function ($scope, close, model, alertService) {

        $scope.data = model.data;


        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Mail ' + model.data.mail + ' wurde gespeichert.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Mail konnte nicht gespeichert werden.');
            });
        };
    });
