angular
    .module('quattro.core.hpls-api-validation', [
        'quattro.core.hpls_api_1',
    ])
    // validate form fields w/ api when `ngModel` is backed by proper model and
    // floating field view.
    .directive("hplsApiValidation", ['$q', 'api', function ($q, api) {

        // inject error message into designer structure
        function setError(inputElement, error) {

            var floatField = inputElement.closest('.float-field');
            var floatMessage = floatField.find('.float-message').first();

            if (error === undefined) {
                floatField.removeClass('message negative');
                floatMessage.text('');
                return;
            }

            api.translate(error)
                .then(function (text) {
                    floatField.addClass('message negative');
                    floatMessage.text(text);
                });
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attributes, controller) {

                var prefixedName = element.attr('ng-model');
                // FIXME: don't hardcode `data`, could be taken from `prefixedName`
                var name = prefixedName.replace(/^data\./, '');

                // mayhap find better way to access model's validate
                // we may access parent controller by depending on them
                // at least make sure, `ng-model` is backed by proper model

                // `scope.data` not yet initialized in all cases, e.g. modals
                var data;

                controller.$asyncValidators.api = function (modelValue, viewValue) {

                    // at this point `scope.data` is set;
                    data = scope.data;

                    // default to `valid` if no api validation can be used
                    if (!data || !data.$validate) {
                        // console.info('no validator found.');
                        return $q.when();
                    }
                    var validation = $q.defer();

                    scope.was_last_validated = viewValue ? viewValue : '';

                    setTimeout( function(){

                        if(scope.was_last_validated.includes(viewValue, 0) && scope.was_last_validated !== viewValue){
                            return $q.when();
                        }

                        var isValid = data.$validate(name, viewValue);

                        if (typeof isValid === 'undefined') {
                            setError(element, 'Error');
                            validation.reject();
                            return validation.promise;
                        }

                        isValid.then(function () {
                            setError(element, undefined);
                            validation.resolve();
                        }, function (error) {
                            setError(element, error);
                            validation.reject();
                        });

                    },600);

                    return validation.promise;
                };

            },
        };
    }]);
