angular
    .module('quattro.core.password-form', [
        'quattro.core.hpls_api_1',
        'quattro.core.password.service',
    ])
    .directive('quattroPasswordForm', [function () {
        return {
            restrict: 'E',
            scope: {
                password: '=',
                validate: '&',
            },
            controller: 'quattroPasswordFormController',
            controllerAs: 'vm',
            templateUrl: 'core/password/quattro_password_form.html',
        };
    }])
    .controller('quattroPasswordFormController', function ($scope, passwordService) {

        var vm = this;

        this.type = 'password';

        this.toggle = function () {
            vm.type = vm.type === 'password' ? 'text' : 'password';
        };

        this.generatePassword = function () {
            passwordService
                .createValid()
                .then(function (result) {
                    $scope.password = result;
                    vm.type = 'text'
                });
        };
    })
    .directive('quattroPasswordForm', ['api', function (api) {

        // to be applied to quattro password form fields.
        // validates using given validator from parent scope,
        // puts translated error message on scope.

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function quattroPasswordFormLink(scope, element, attrs, ngModelController) {

                ngModelController.$asyncValidators.quattroPasswordForm = function validate(modelValue, viewValue) {
                    var isValid = scope.validate({value: viewValue});

                    isValid
                        .then(null, api.translate)
                        .then(function (text) {
                            scope.password_$error = text;
                        });

                    return isValid;
                };
            },
        };
    }]);
