angular
    .module('quattro.core.alert.directive', [
        'quattro.core.alert.service',
    ])
    .directive('quattroAlerts', [function () {

        return {
            restrict: 'E',
            scope: {},
            controller: function (alertService) {
                this.list = alertService.list;
                this.close = alertService.remove;
            },
            controllerAs: 'vm',
            templateUrl: 'core/alert/alert-directive.html',
        };
    }]);
