angular
    .module('quattro.jpb.contact-forms.db-delete', [
        'quattro.core.support',
        'quattro.core.modal-confirm',
    ])
    .run(function ($rootScope, myModalService) {

        $rootScope.$on('vq.contact.db-delete', function (event, account) {

            myModalService.showModal({
                templateUrl: 'jpb/contact-forms/modal-contact-db-delete.html',
                controller: 'ContactFormDbDeleteController',
                controllerAs: 'vm',
                inputs: {
                    account: account,
                }
            });

        });
    })
    .controller('ContactFormDbDeleteController', function ($interpolate, $templateCache, close, $scope, account, SupportService, alertService, $translate) {

        $scope.data = {
            account: account,
        };

        this.cancel = close;

        this.submit = function () {
            $scope.data.dbuser_class = '';
            if (!$scope.data.dbuser) {
                $scope.data.dbuser_class = 'input-mandatory-warning';
                alertService.add('error', $translate('CONTACT.mandatory_error'));
            } else {
                var templateSupport = $templateCache.get('jpb/contact-forms/mail-templates/db-delete.support.txt');
                var templateCustomer = $templateCache.get('jpb/contact-forms/mail-templates/db-delete.customer.txt');
                var templateSubject = $templateCache.get('jpb/contact-forms/mail-templates/db-delete.subject.txt');

                var textSupport = $interpolate(templateSupport)($scope.data);
                var textCustomer = $interpolate(templateCustomer)($scope.data);
                var subject = $interpolate(templateSubject)($scope.data).trim();

                SupportService.create(subject, textSupport, subject, textCustomer).then(function () {
                    close();
                    alertService.add('info', $translate('CONTACT.inquiry_success'));
                });
            }
        };

    });
