angular.module('quattro.logout', ['ngRoute'])
    .controller('LogoutCtrl', function ($scope, $log, $location, api, alertService, userService) {

        function doLogout() {
            alertService.clear();
            userService.logout();
            $location.path('/login');
        }

        api.single('deauth', {})
            .then(function () {
                doLogout();
            }, function (error) {

                // 5 no auth
                // 253 invalid auth
                if (error.code === 5 || error.code === 253) {
                    $log.info('session expired, force logout.');
                    doLogout();
                    return;
                }

                $log.error('logout failed! code: ' + error.code + ' - message: ' + error.message);
                alertService.add('error', api.translate(error));
            });

    });
