angular
    .module('quattro.account.widget_invoices', [])
    .directive('accountinvoices', function () {
        return {
            templateUrl: 'jpb/account/widget_invoices.html',
            controllerAs: 'vm',
            controller: function ($scope, $log, api) {

                function load() {
                    api.single('q.account.invoice.list', {'account': $scope.userName})
                        .then(handleResult, handleError);
                }

                function handleResult(result) {
                    $scope.invoices = result;
                }

                function handleError(error) {
                    $log.error('q.account.invoice.list failed! code: ' + error.code + ' - message: ' + error.message);
                }

                this.clicked = function () {
                    // reload will generate new tokens
                    load();
                };

                this.more = function () {
                    $scope.limit += 12
                };

                this.hasMore = function () {
                    return $scope.limit <= $scope.invoices.length
                }

                // currently not in use
                $scope.viewInvoice = function (rnr) {
                    api.single('q.account.invoice.get', {
                        'account': $scope.userName,
                        'rnr': rnr,
                        'gzoff': false
                    })
                        .then(null, function (error) {
                            $log.error('q.account.invoice.get failed! code: ' + error.code + ' - message: ' + error.message);
                        });
                }

                // init
                $scope.invoices = [];
                $scope.limit = 12;
                $scope.$watchGroup(['limit', 'invoices'], this.hasMore);
                load();
            }
        };
    });
