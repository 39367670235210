angular.module('quattro.core.user.logout', [
    'quattro.core.hpls_api_1',
    'quattro.core.alert.service',
    'quattro.core.user.service',
])
    .controller('LogoutController', function ($scope, $log, $location, api, alertService, userService) {

        function logout() {
            alertService.clear();
            userService.logout();
            $location.path('/login');
        }

        api.single('deauth', {})
            .then(function () {
                logout();
            }, function (error) {

                // 5 no auth
                // 253 invalid auth
                if (error.code === 5 || error.code === 253) {
                    $log.info('session expired, force logout.');
                    logout();
                    return;
                }

                $log.error('logout failed! code: ' + error.code + ' - message: ' + error.message);
                alertService.add('error', api.translate(error));
            });

    });
