angular
    .module('quattro.mailinglist.modal_add', [
        'quattro.model',
        'quattro.mailinglist.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Mailinglist', 'myModalService', function ($rootScope, Mailinglist, myModalService) {

        $rootScope.$on('vq.mailinglist.add', function (event, mailinglist, callback) {

            var model = new Mailinglist(mailinglist, 'create');

            myModalService.showModal({
                templateUrl: 'jpb/mailinglist/modal_add.html',
                controller: 'ModalAddMailinglistController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    callback && callback();
                });
            });
        });
    }])
    .controller('ModalAddMailinglistController', function ($scope, close, model, alertService) {

        $scope.data = model.data;

        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.create();
            promise.then(function () {
                alertService.add('success', 'Mailinglist wurde angelegt.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Mailinglist konnte nicht angelegt werden.');
            });
        };
    });
