angular.module('quattro.jpb.model.mail', [
    'quattro.core.model.prototype',
])
    .factory('Mail', function ($log, api, Model, userService) {

        var prototype = Model.prototype;

        // clarify internal naming here
        function getDefaultData() {
            return {
                // primary key
                domain: undefined,
                mail: undefined,

                memo: undefined,
                password: undefined,

                catchall: false,
                forwards: [],
                inboxsave: true,
                type: undefined,   // read only

                vac_enabled: false,
                vac_subject: undefined,
                vac_text: undefined,
                vac_date_start: undefined,
                vac_date_end: undefined,

                // admin stuff
                status: undefined,
                pass_clear: undefined,
            };
        }


        var authorization = {
            read: 'all',
            create: ['admin', 'account', 'domain'],
            update: {
                default: ['admin', 'account', 'domain', 'mail'],
                attributes: {
                    pass_clear: ['admin'],
                    status: ['admin'],
                }
            },
            remove: ['admin', 'account', 'domain'],
        };


        var scenarios = {
            read: [
                {
                    method: 'q.mail.get',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                    },
                    results: {
                        status: null,
                        domain_flags: null,
                        familienservice: {
                            filter: function (x, result) {
                                // boolean, `true` if familienservice restrictions
                                // apply to this mail address.
                                var level = userService.get('userLevel');
                                return (level === 'mail' && result && result.domain_flags || []).indexOf('familienservice') !== -1;
                            },
                        },
                        allow_forwards: {
                            filter: function (x, result) {
                                // disallow forwards when userlevel 'mail' and
                                // domain flag 'no_mail_forwards' present, INTDEV-889
                                var level = userService.get('userLevel');
                                return (level === 'mail' && result && result.domain_flags || []).indexOf('no_mail_forwards') === -1;
                            },
                        },
                        memo: null,
                        pass_clear: null,
                        forwards: null,
                        type: null,
                        inboxsave: {
                            filter: function (x, result) {
                                return (['inbox', 'inboxforward'].indexOf(result.type) !== -1);
                            }
                        },
                        catchall: {
                            filter: function (x, result) {
                                return result.mail && result.mail.startsWith('@');
                            }
                        },
                    }
                },
                {
                    method: 'q.mail.sieve.get',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                    },
                    results: {
                        vac_enabled: null,
                        vac_subject: null,
                        vac_text: null,
                        vac_date_start: null,
                        vac_date_end: null,
                    },
                    skip_if: function (data) {
                        // `data.catchall` may not be initialized yet :-/
                        return data.mail && data.mail.startsWith('@');
                    }
                }
            ],
            create: [
                {
                    method: 'q.domain.mail.add',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'mail': {
                            required: true,
                            filter: function (x, data) {
                                // `x` contains domain part
                                if (x.indexOf('@') !== -1) {
                                    return x;
                                }
                                // `x` contains only local part
                                return x + '@' + data.domain;
                            }
                        },
                        'password': {
                            required: true,
                            api_name: 'pass',
                        },
                        'inboxsave': true,
                        'forwards': true,
                        forwards_item: {
                            api_name: 'forwards',
                            validate_only: true,
                            undefined_if_empty: true,
                            filter: function (x) {
                                return [x];
                            }
                        },
                        'memo': null,
                        'catchall': null,
                    },
                }
            ],
            update: [
                {
                    method: 'q.mail.set',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                        'status': null,
                        'pass_clear': {
                            undefined_if_empty: true,
                        },
                    },
                },
                {
                    method: 'q.mail.memo.set',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                        'memo': null,
                    },
                },
                {
                    method: 'q.mail.inboxsave.set',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                        'inboxsave': null,
                    },
                    delay_if: function (data) {
                        // send this command last when `false`, prevent runtime w/ forwards
                        return data.inboxsave === false;
                    },
                    skip_if: function (data) {
                        return data.catchall;
                    }
                },
                {
                    method: 'q.mail.forward.set',
                    params: {
                        mail: {
                            primary_key: true,
                        },
                        forwards: null,
                        forwards_item: {
                            api_name: 'forwards',
                            validate_only: true,
                            undefined_if_empty: true,
                            filter: function (x) {
                                return [x];
                            }
                        },
                    },
                    delay_if: function (data) {
                        // send this command last when list empty, prevent runtime w/ inboxsave
                        return data.forwards.length === 0;
                    },
                },
                {
                    method: 'q.mail.password.set',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                        'password': {
                            undefined_if_empty: true,
                        },
                    },
                },
                {
                    method: 'q.mail.sieve.set',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                        'vac_enabled': true,
                        'vac_subject': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            filter: function (x, data) {
                                // empty string: dovecot sets subject to "Auto: <orig subject>"
                                return data.familienservice ? '' : x;
                            },
                        },
                        'vac_text': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            required_if: function (data) {
                                return data.vac_enabled;
                            }
                        },
                        'vac_date_start': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            filter: prototype.filter.UTCToLocale,
                            undefined_if_empty: true
                        },
                        'vac_date_end': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            filter: prototype.filter.UTCToLocale,
                            undefined_if_empty: true
                        },
                    },
                    skip_if: function (data) {
                        return data.catchall;
                    }
                },
            ],
            remove: [
                {
                    method: 'q.domain.mail.del',
                    params: {
                        domain: true,
                        mail: true,
                    },
                },
            ],
        };


        function addForwards(newForwards) {
            var data = this.data;
            var mail = data.mail;
            var forwards = data.forwards.slice();
            var promise;

            if (!mail) {
                return false;
            }

            // append new forwards to our list, ommit doublettes
            newForwards.forEach(function (item) {
                if (forwards.indexOf(item) === -1) {
                    forwards.push(item);
                }
            });

            promise = api.single('q.mail.forward.set', {
                mail: mail,
                forwards: forwards
            });
            promise.then(null, function (error) {
                $log.error('q.mail.forward.set', error.code, error.message);
            });
            return promise;
        }


        function removeForwards(delForwards) {
            var data = this.data;
            var mail = data.mail;
            var forwards = data.forwards.slice();
            var index, promise;

            if (!mail) {
                return false;
            }

            // remove to be deleted forwards from our list
            delForwards.forEach(function (item) {
                index = forwards.indexOf(item);
                if (forwards.indexOf(item) !== -1) {
                    forwards.splice(index, 1);
                }
            });

            promise = api.single('q.mail.forward.set', {
                mail: mail,
                forwards: forwards
            });
            promise.then(null, function (error) {
                $log.error('q.mail.forward.set', error.code, error.message);
            });
            return promise;
        }


        function query(domain) {
            var promise = api.single('q.domain.mail.list', {
                domain: domain,
            });
            promise.then(null, function (error) {
                $log.error('q.domain.mail.list', error.code, error.message);
            });
            return promise;
        }


        function Mail(config, scenario) {
            this.scenario = scenario;
            var data = scenario === 'create' ? getDefaultData() : {};
            this.data = angular.merge(data, config);
            this.data.$validate = this.validate.bind(this);
        }

        // static
        Mail.query = query;

        Mail.prototype = angular.merge(new Model(), {
            scenarios: scenarios,
            authorization: authorization,
            addForwards: addForwards,
            removeForwards: removeForwards,
        });

        return Mail;
    });
