angular.module('quattro.core.user.login', [
    'quattro.core.hpls_api_1',
    'quattro.core.alert.service',
    'quattro.core.user.service',
])
    .controller('LoginController', function ($scope, $log, $location, $window, api, alertService, userService) {

        // login controller for bmbo, for jpb look jpb/site/
        $scope.login = {};

        // make sure there is no modal open.
        // since our modal can't be closed from outside we have to mock a
        // click on the overlay.
        var body = angular.element('body');
        var main = body.find('main#main');
        main.trigger('click');

        $scope.login = function () {

            var user = $scope.login.user;
            var pass = $scope.login.pass;

            // force logout before login
            userService.logout();

            api.single('auth', {'user': user, 'pass': pass})
                .then(function (result) {

                    var hplsAuth = result && result.session;
                    var userLevel = result && result.level;

                    if (!hplsAuth && !userLevel) {
                        $log.error('API error: neither error nor session id or user level received.');
                        alertService.add('error', 'API error: neither error nor session id or user level received.');
                        return; // early exit
                    }

                    if (userLevel === 'mail' || userLevel === 'reseller' || userLevel === 'domain' || userLevel === 'ftp') {
                        $log.error('Error: user level "mail", "domain", "ftp" and "reseller" not allowed');
                        return; // early exit
                    }

                    alertService.clear();
                    userService.login(hplsAuth, user, userLevel);

                    $location.path('/');

                }, function (error) {
                    if (error.code == 253) {
                        $log.error('invalid auth id!');
                    }
                    $log.error('auth failed! code: ' + error.code + ' - message: ' + error.message);
                    alertService.add('error', api.translate(error));
                });
        };
    });
