angular.module('quattro.jpb.model.incident', [
    'quattro.core.model.prototype',
])
    .factory('Incident', function ($log, api, Model) {

        function getDefaultData() {
            return {
                // primary key
                incident_jpberlin: '', // incident message for JPBerlin
                incident_heinlein_support: '', // incident message for Heinlein-Support
                incident_europostfach: '', // incident message for Europostfach
            };
        }

        var authorization = {
            read: 'all',
            update: {
                default: ['admin', 'incident'],
                attributes: {
                    pass_clear: ['admin'],
                    status: ['admin'],
                }
            },
        };

        var scenarios = {
            read: [
                {
                    method: 'q.incident.get',
                    params: {},
                    results: getDefaultData()
                },
            ],
            update: [
                {
                    method: 'q.incident.set',
                    params: {
                        'incident_jpberlin': {
                            required: false,
                            always_send: true,
                        },
                        'incident_heinlein_support': {
                            required: false,
                            always_send: true,
                        },
                        'incident_europostfach': {
                            required: false,
                            always_send: true,
                        },
                    },
                    results: {}
                },
            ],
        };

        function Incident(config, scenario) {
            this.scenario = scenario;
            var data = getDefaultData();
            this.data = angular.merge(data, config);
        }

        Incident.prototype = angular.merge(new Model(), {
            scenarios: scenarios,
            authorization: authorization,
        });

        return Incident;
    });
