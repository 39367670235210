$(function () {

    var html = $('html');
    var body = $('body');

    body.on('click', '[data-action="toggle"]', function () {
        var target = $('[data-object="toggle"][data-target="' + $(this).data('target') + '"]');
        actionOpen(target);
    });

    var thisOpen = false;

    function actionOpen(target) {
        if (!thisOpen) {
            target.addClass('initial'); // We add the inital class to display the object before we animate it
            setTimeout(function () {
                target.addClass('active'); // This class triggers the animation
                html.addClass(target.attr('id'));
            }, 1);
            thisOpen = true;
        } else {
            target.removeClass('active'); // This class trigger the animation
            html.removeClass(target.attr('id'));
            setTimeout(function () {
                target.removeClass('initial');	// After the style animation is done we finally hide it
            }, 300);
            thisOpen = false;
        }
    }

    body.on('click', '#main', function () {
        if (html.hasClass('menu')) {
            var target = $('[data-target="menu"]').not('[data-action]');
            actionOpen(target);
        }
    });

    $('#search-handler').click(function () {
        $('#search-box input').focus();
    });

    body.on('click', '.itemlist .thumb-search', function () {
        var itemlist = $(this).closest('.itemlist');
        itemlist.addClass('show-filter');
        itemlist.find('.search input').focus();
    });

    body.on('click', '.itemlist [data-action="close-search"]', function () {
        $(this).closest('.itemlist').find('.search input').val('').trigger('input');
        $(this).closest('.itemlist').removeClass('show-filter');
    });

    body.on('change', '.itemlist .panel-body .checkbox', function () {
        var itemList = $(this).closest('.itemlist');
        var checkAll = itemList.find('.panel-heading .checkbox');
        var checkboxes = itemList.find('.panel-body .checkbox');
        var checked = checkboxes.filter(':checked');

        // show / hide contextual
        if (checked.length > 0) {
            itemList.addClass('show-contextual');
        } else {
            itemList.removeClass('show-contextual');
        }

        // check / uncheck 'checked-all' checkbox
        if (checkboxes.length === checked.length) {
            checkAll.prop('checked', true);
        } else {
            checkAll.prop('checked', false);
        }
    });

    body.on('change', '.itemlist .panel-heading .checkbox', function () {
        var checkboxes = $(this).closest('.itemlist').find('.panel-body .checkbox');
        if ($(this).is(':checked')) {
            checkboxes.filter(':not(:checked)').click();
        } else {
            checkboxes.filter(':checked').click();
        }
    });

    body.on('click', '.itemlist [data-action="close-contextual"]', function () {
        $(this).closest('.itemlist').removeClass('show-contextual');
        $(this).closest('.itemlist').find('.checkbox').prop('checked', false);
    });

    /*
    --------------------------------------------------------------------------------
        Floating Labels
    --------------------------------------------------------------------------------
    */

    body.on('checkval', 'input, select, textarea', function () {

        var floatLabel = $(this).closest('.float-field');

        if (this.value !== '') {
            floatLabel.addClass('active');
        } else {
            floatLabel.removeClass('active');
        }

    }).on('change', 'input, select, textarea', function () {

        $(this).trigger('checkval');

    }).on('keyup', 'input, select, textarea', function () {

        $(this).trigger('checkval');

    }).on('focus', 'input, select, textarea', function () {

        $(this).closest('.float-field').addClass('on');

    }).on('blur', 'input, select, textarea', function () {

        $(this).closest('.float-field').removeClass('on');

    });

    /*
    --------------------------------------------------------------------------------
        Auto expanding Textarea
    --------------------------------------------------------------------------------
    */

    body.on('input checkval', 'textarea', function () {

        // disabled expanding textarea
        // expanding scrolls page to top of textarea, which breaks editing when
        // textarea is bigger than screen.
        // ref VQ-408

        // workaround: textarea min height like content, maximum 200px, apart
        // from that enable browsers' scrolling and resize in css

        var textarea = this;
        var height = textarea.scrollHeight;
        $(textarea).css('min-height', (height <= 240 ? height : 240) + 'px');


        // disabled auto expand code:

        //var rows;
        //$(this).parent('.float-area').css('min-height', this.scrollHeight);
        //this.rows = 1;
        //rows = this.scrollHeight / parseInt($(this).css('line-height'));
        //this.rows = rows;
        //$(this).parent('.float-area').css('min-height', 0);
    });

    $(window).resize(function () {
        body.trigger('input');
    });
});
