angular
    .module('quattro.ftp.modal_edit', [
        'quattro.model',
        'quattro.ftp.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', '$filter', 'Ftp', 'myModalService', function ($rootScope, $filter, Ftp, myModalService) {

        $rootScope.$on('vq.ftp.edit', function (event, ftp, callback) {

            var domain = $filter('ftp_domain_part')(ftp.ftp);

            // reflect modal state on ftp's scope
            ftp.$active = true;

            var model = new Ftp({
                domain: domain,
                ftp: ftp.ftp,
            });
            model.get();

            myModalService.showModal({
                templateUrl: 'jpb/ftp/modal_edit.html',
                controller: 'ModalEditFtpController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    ftp.$active = false;
                    callback && callback();
                });
            });

        });
    }])
    .controller('ModalEditFtpController', function ($scope, close, model, alertService) {

        $scope.data = model.data;

        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Ftp gespeichert.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Ftp konnte nicht gespeichert werden.');
            });
        };
    });
