angular
    .module('quattro.core.alias.form', [])
    .directive('quattroAliasForm', ['$q', '$translate', function ($q, $translate) {

        function add(scope) {
            var aliases = scope.aliases;
            var alias = scope.alias;
            if (alias && aliases.indexOf(alias) === -1) {
                aliases.push(alias);
            }
            scope.alias = '';
        }

        function remove(scope, alias) {
            var aliases = scope.aliases;
            var index = aliases.indexOf(alias);
            if (index !== -1) {
                aliases.splice(index, 1);
            }
        }


        return {
            restrict: 'E',
            scope: {
                aliases: '=',
                validateAlias: '&validateAlias',
                control: '=?',
            },
            templateUrl: 'core/alias/alias-form.html',
            link: function (scope, element, attributes) {

                scope.alias = '';

                // expose active api to outside
                if (scope && scope.control) {
                    scope.control.flush = function () {
                        scope.add();
                    };
                }

                // enable custom validation directive
                scope.data = scope;

                scope.$validate = function (name, value) {
                    // temporarily disable validation for aliases while typing -> see DEV-13287
                    return $q.when();

                    if (name === 'alias') {
                        if (value === '') {
                            return $q.when();
                        }
                        // silent error??
                        return scope.validateAlias({value: value});
                    }
                    return $q.when();
                };

                scope.add = function () {
                    add(scope);
                };

                scope.remove = function (alias) {
                    remove(scope, alias);
                };

            }
        };
    }]);
