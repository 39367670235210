angular
    .module('quattro.ftp.form', [
        'quattro.core.viewhelper',
        'quattro.core.user',
        'quattro.directives',
        'quattro.core.password-form',
    ])
    .directive('quattroFtpForm', [function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                isCreate: '=?',
                cancel: '=',
                // will be called after successfull validation
                save: '=',
            },
            controller: 'ftpFormController',
            controllerAs: 'vm', // refactor ongoing, this is target
            templateUrl: 'jpb/ftp/widget_form.html',
        };
    }])
    .controller('ftpFormController', ['$scope', 'userService', 'viewHelper', function ($scope, userService, viewHelper) {

        this.user = userService.scope;

        this.cancel = $scope.cancel;

        this.save = function () {

            // ngForm.$validate();

            if (!viewHelper.formIsValid()) {
                return;
            }

            $scope.save();
        };
    }]);
