angular
    .module('quattro.core.country-picker', [
        'pascalprecht.translate',
        'quattro.core.config',
        'quattro.core.filters',
    ])
    .directive('quattroCountryPicker', function () {
        return {
            restrict: 'A',
            scope: {},
            controller: 'quattroCountryPickerController',
            controllerAs: 'vm',
            template: [
                '<optgroup>',
                '<option ng-repeat="country in vm.countries_suggest" value="{{country}}" translate>{{ country | prefix:"COUNTRY." }}</option>',
                '<option disabled>&nbsp;</option>',
                '<option ng-repeat="country in vm.countries_all" value="{{country}}" translate>{{ country | prefix:"COUNTRY." }}</option>',
                '</optgroup>',
            ].join(' '),
        };
    })
    .controller('quattroCountryPickerController', function (countries, suggested_countries) {
        this.countries_all = countries;
        this.countries_suggest = suggested_countries;
    });
