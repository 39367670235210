angular
    .module('quattro.forward.widget_edit', [])
    .directive('forwardsEdit', ['$q', '$translate', function ($q, $translate) {

        function add(scope) {
            var forwards = scope.forwards;
            var item = scope.item;
            if (item) {
                forwards.push(item);
                scope.item = '';
            }
        }

        function remove(scope, item) {
            var forwards = scope.forwards;
            var index = forwards.indexOf(item);
            var inboxsaveDisabled = scope.inboxsaveDisabled;

            // don't remove last entry when inboxsave is disabled
            if (inboxsaveDisabled && forwards.length === 1) {
                return;
            }
            if (index !== -1) {
                forwards.splice(index, 1);
            }
            if (!forwards.length) {
                scope.inboxsave = true;
            }
        }


        return {
            restrict: 'E',
            scope: {
                forwards: '=',
                inboxsave: '=',
                inboxsaveDisabled: '=?',
                validateForward: '&validateForward',
                validateInboxsave: '&validateInboxsave',
                control: '=?',
            },
            templateUrl: 'jpb/forward/widget_edit.html',
            link: function (scope, element, attributes) {

                scope.item = '';

                // expose active api to outside
                if (scope && scope.control) {
                    scope.control.flush = function () {
                        scope.add();
                    };
                }

                // enable custom validation directive
                scope.data = scope;

                scope.$validate = function (name, value) {
                    if (name === 'item') {
                        if (value === '') {
                            return $q.when();
                        }
                        // silent error??
                        return scope.validateForward({value: value});
                    }
                    if (name === 'inboxsave') {
                        return scope.validateInboxsave({value: value});
                    }
                    return $q.when();
                };

                scope.add = function () {
                    add(scope);
                };

                scope.remove = function (item) {
                    remove(scope, item);
                };

            }
        };
    }]);
