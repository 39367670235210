angular
    .module('quattro.email.widget_forwardlist', [
        'quattro.core.modal-confirm',
    ])
    .directive('forwardlist', function () {
        return {
            templateUrl: 'jpb/email/widget_forwardlist.html',
            controller: function ($scope, alertService, myModalService) {

                $scope.orderByExpression = '';
                $scope.orderByReverse = false;

                $scope.orderBy = function (predicate) {
                    $scope.orderByReverse = ($scope.orderByExpression === predicate) ? !$scope.orderByReverse : false;
                    $scope.orderByExpression = predicate;
                };
                $scope.orderedClass = function (predicate) {
                    if ($scope.orderByExpression === predicate) {
                        return $scope.orderByReverse ? 'sort-desc' : 'sort-asc';
                    }
                    return '';
                };

                $scope.forwardlistSelected = function () {
                    var list = $scope.forwardlist;
                    var filtered = list.filter(function (item) {
                        return item.$checked;
                    });
                    return filtered.length ? filtered : list;
                };

                // FIXME: model function bound to scope, not good...
                var removeForwards = $scope.removeForwards.bind($scope);

                $scope.deleteSelectedForwards = function () {

                    var list = $scope.forwardlistSelected().map(function (value) {
                        return value.address;
                    });

                    myModalService.showModal({
                        templateUrl: 'jpb/forward/modal_confirm_delete.html',
                        inputs: {
                            list: list,
                            reload: $scope.reload,
                        },
                        controller: ['$scope', 'close', 'list', 'reload', function ($scope, close, list, reload) {
                            $scope.list = list;
                            $scope.cancel = function () {
                                close();
                            };
                            $scope.confirm = function () {
                                close();
                                removeForwards(list)
                                    .then(function () {
                                    }, function () {
                                        alertService.add('error', 'Konnten nicht alle Forwards gelöscht werden.');
                                    })
                                    .finally(function () {
                                        reload();
                                        angular.element('.itemlist').removeClass('show-contextual');
                                    });
                            };
                        }],
                    });
                };
            }
        };
    });
