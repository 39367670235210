angular
    .module('quattro.core.hpls_api_1', [
        'pascalprecht.translate',
        'quattro.core.user.service',
        'quattro.core.tools', // `curryIt`
    ])
    .provider('api', function () {

        // jsonrpc2 client via http
        //
        // `request` in this file is an jsonrpc request-like object w/ `method` and
        // `params` attributes and optional `onFulfilled` and `onRejected` handlers.
        // actual requests w/o handlers are called `jsonrpcRequest`.

        var $http, $q, userService;

        function noop() {
        }

        // configurable
        var provider = this;
        this.id = 'quattro';
        this.url = '';

        var httpCounter = 0;
        var scope = {
            loading: false
        };


        function beforeHttp() {
            httpCounter++;
            scope.loading = httpCounter !== 0;
        }

        function afterHttp() {
            httpCounter--;
            scope.loading = httpCounter !== 0;
        }

        // sends payload via http, returns http promise.
        function http(payload) {
            var authid = userService.get('hpls-auth');
            beforeHttp();
            var httpDone = $http.post(provider.url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'HPLS-AUTH': authid
                }
            });
            httpDone.finally(function () {
                afterHttp();
            });
            return httpDone;
        }


        // return jsonrpc error representing given http error
        function http2jsonrpc(http) {
            return {
                error: {
                    code: 'http_' + http.status,
                    message: 'HTTP error ' + http.status + ', ' + http.statusText
                }
            };
        }


        // evaulate given jsonrpc response and execute request's `onFulfilled` and
        // `onRejected` handlers, if given.
        // returns false if response is error, true otherwise.
        function handleJsonResponse(jsonrpcResponse, request) {
            jsonrpcResponse = jsonrpcResponse || {};
            request = request || {};
            var error = jsonrpcResponse.error;
            var result = jsonrpcResponse.result;
            var fulfill = request.onFulfilled || noop;
            var reject = request.onRejected || noop;

            // auth id invalid
            if (error && error.code === 253) {
                userService.sessionExpired();
            }

            if (error === undefined && result !== undefined) {
                fulfill(result);
                return true;
            }

            if (result === undefined && error && error.code !== undefined && error.message !== undefined) {
                reject(error);
                return false;
            }

            // more strict
            //if (result === undefined && error && typeof error.code === 'number' && typeof error.message === 'string') {
            //    reject(error);
            //    return false;
            //}

            reject({
                code: 'xxx',
                message: 'invalid response.'
            });
        }


        // send given request, which must have `method` and `params`, and may have
        // `onFulfilled` and `onRejected` handlers.
        // returns promise, which fulfills when request was successful or rejects on
        // error.
        function single(request) {

            // deprecated
            if (arguments.length === 2) {
                request = {
                    method: arguments[0],
                    params: arguments[1]
                };
            }

            request.params = request.params || {};

            var id = provider.id;
            var jsonrpcRequest = {
                jsonrpc: '2.0',
                id: id,
                method: request.method,
                params: request.params
            };
            var httpDone = http(jsonrpcRequest);
            return httpDone.then(function (http) {
                return handleJsonResponse(http.data, request) ? $q.when(http.data.result) : $q.reject(http.data.error);
            }, function (http) {
                var error = http2jsonrpc(http);
                handleJsonResponse(error, request);
                return $q.reject(error);
            });
        }


        // send array of requests. bool batch if to be sent in one http request.
        // a request must have `method` and `params`, and may have `onFulfilled` and
        // `onRejected` handlers.
        // returns promise which fulfills when all requests were successful or
        // rejects when at least one failed.
        function many(requests) {
            var promises = requests.map(single);
            return $q.all(promises).then(function () {
                return $q.when();
            }, function (errorData) {
                return $q.reject(errorData);
            });
        }


        // send array of requests in one http request.
        // a request must have `method` and `params`, and may have `onfulfilled` and
        // `onrejected` handlers.
        // returns promise which fulfills when all requests were successful or
        // rejects when at least one failed.
        function batch(requests) {

            var idPrefix = provider.id + '-';
            var jsonrpcRequests = requests.map(function (request, index) {
                return {
                    jsonrpc: '2.0',
                    id: idPrefix + index,
                    method: request.method,
                    params: request.params
                };
            });
            var httpDone = http(jsonrpcRequests);
            return httpDone.then(function (http) {
                var data = http.data;
                var failed;

                if (Object.prototype.toString.call(data) !== '[object Array]') {
                    // single response object, spread it to all
                    failed = requests.filter(function (request) {
                        return !handleJsonResponse(data, request);
                    });
                    // early exit
                    return failed.length === 0 ? $q.when() : $q.reject();
                }

                failed = data.filter(function (jsonrpcResponse) {
                    var id = jsonrpcResponse.id;
                    var index = id.substr(idPrefix.length);
                    index = Number.parseInt(index);
                    var request = requests[index] || {};
                    return !handleJsonResponse(jsonrpcResponse, request);
                });
                return failed.length === 0 ? $q.when() : $q.reject();
            }, function (http) {
                requests.forEach(function (request) {
                    handleJsonResponse(http2jsonrpc(http), request);
                });
                return $q.reject();
            });
        }


        // translates a given api error code, fallback if translation failed.
        // needs api related language files
        // @error jsonrpc error object w/ `code` and `message` attributes
        // returns promise which, since fallback, will always succeed.
        function translate($translate, error) {

            var code = error.code;
            var message = error.message;

            var textId = 'API.ERR_' + code;
            return $translate(textId).then(null, function () {
                return '[' + code + '] ' + message;
            });
        }


        this.$get = ['$http', '$translate', '$q', 'curryIt', 'userService', function (_$http_, $translate, _$q_, curryIt, _userService_) {
            $http = _$http_;
            $q = _$q_;
            userService = _userService_;
            return {
                scope: scope,
                single: single,
                many: many,
                batch: batch,
                translate: curryIt(translate, $translate),
            };
        }];
    });
