angular
    .module('quattro.core.support', [
        'quattro.core.hpls_api_1'
    ])
    .factory('SupportService', function (api) {

        function supportCreate(subjectSupport, textSupport, subjectCustomer, textCustomer) {
            return api.single({
                method: 'q.support.create',
                params: {
                    subject: subjectSupport,
                    body: textSupport,
                    subject_customer: subjectCustomer,
                    body_customer: textCustomer,
                }
            });
        }

        return {
            create: supportCreate,
        };
    });
