angular
    .module('quattro.domain.widget_domainlist', [
        'ngRoute',
        'quattro.model',
        'quattro.domain.modal_add',
        'quattro.domain.modal_edit',
    ])
    .directive('domainlist', function () {
        return {
            templateUrl: 'jpb/domain/widget_domainlist.html',
            controller: ['$scope', '$log', 'Domain', function ($scope, $log, Domain) {

                var account = $scope.userName;

                $scope.modalAddDomain = function () {
                    $scope.$emit('vq.domain.add', {account: account,}, function () {
                        $scope.loadDomainList();
                    });
                };

                $scope.quickEditDomain = function (domain) {
                    $scope.$emit('vq.domain.edit', domain);
                };

                $scope.loadDomainList = function () {
                    var promise = Domain.query(account);
                    promise.then(function (result) {
                        $scope.domainlist = result;
                    });
                };

                $scope.loadDomainList();

                $scope.orderByExpression = 'domain';
                $scope.orderByReverse = false;

                $scope.orderBy = function (predicate) {
                    $scope.orderByReverse = ($scope.orderByExpression === predicate) ? !$scope.orderByReverse : false;
                    $scope.orderByExpression = predicate;
                };
                $scope.orderedClass = function (predicate) {
                    if ($scope.orderByExpression === predicate) {
                        return $scope.orderByReverse ? 'sort-desc' : 'sort-asc';
                    }
                    return '';
                };

                function cleanCSVList(domain) {
                    return {
                        domain: domain.domain,
                        count_mails: domain.count_mails,
                        count_ftps: domain.count_ftps
                    };
                }

                $scope.domainlistSelected = function () {
                    var list = $scope.domainlist;
                    var filtered = list.filter(function (item) {
                        return item.$checked;
                    });
                    return (filtered.length ? filtered : list).map(cleanCSVList);
                };
            }]
        };
    });
