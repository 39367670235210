angular
    .module('quattro.jpb.contact-forms.ap-new', [
        'quattro.core.support',
        'quattro.core.modal-confirm',
    ])
    .run(function ($rootScope, myModalService) {

        $rootScope.$on('vq.contact.ap-new', function (event, account) {

            myModalService.showModal({
                templateUrl: 'jpb/contact-forms/modal-contact-ap-new.html',
                controller: 'ContactFormApNewController',
                controllerAs: 'vm',
                inputs: {
                    account: account,
                }
            });

        });
    })
    .controller('ContactFormApNewController', function ($interpolate, $templateCache, close, $scope, account, SupportService, alertService, $translate) {

        $scope.data = {
            account: account,
            actionAdd: false,
            actionChange: false,
            actionRemove: false,
        };

        $scope.input = {
            addContacts: [],
            changeContacts: [],
            removeContacts: [],
        };

        $scope.addEntry = function (array) {
            array.push({name: '', mail: '', responsibilities: ''});
        };

        $scope.removeEntry = function (array, index) {
            array.splice(index, 1);
        };

        // add 1 empty start entry by default
        $scope.addEntry($scope.input.addContacts);
        $scope.addEntry($scope.input.changeContacts);
        $scope.addEntry($scope.input.removeContacts);

        this.cancel = close;

        this.submit = function () {
            $scope.data.actionCheckboxesClass = '';

            $scope.data.addContactsString = '';
            $scope.data.changeContactsString = '';
            $scope.data.removeContactsString = '';

            function validateEntry(entry, requireReponsibilities) {
                // Reset classes when re-validating
                entry.nameClass = '';
                entry.mailClass = '';
                entry.responsibilitiesClass = '';

                var okay = true;
                if (!entry.name) {
                    entry.nameClass = 'input-mandatory-warning';
                    okay = false;
                }
                if (!entry.mail) {
                    entry.mailClass = 'input-mandatory-warning';
                    okay = false;
                }
                if (requireReponsibilities && !entry.responsibilities) {
                    entry.responsibilitiesClass = 'input-mandatory-warning';
                    okay = false;
                }
                return okay;
            }

            var validationError = false;

            function stringifyContactsArray(array, withReponsibilities) {
                var contactsString = '';
                var hasEntries = false;
                array.forEach(function (entry) {
                    if (entry.name || entry.mail || entry.responsibilities) {
                        hasEntries = true;
                        if (validateEntry(entry, withReponsibilities)) {
                            var line = entry.name + ', ' + entry.mail;
                            if (withReponsibilities) {
                                line += ', ' + entry.responsibilities;
                            }
                            contactsString += line + '\n';
                        } else {
                            validationError = true;
                        }
                    } else {
                        entry.nameClass = entry.mailClass = entry.responsibilitiesClass = '';
                    }
                });
                if (!hasEntries) {
                    validationError = true;
                    if (!array[0]) {
                        $scope.addEntry(array);
                    }
                    array[0].nameClass = 'input-mandatory-warning';
                    array[0].mailClass = 'input-mandatory-warning';
                    if (withReponsibilities) {
                        array[0].responsibilitiesClass = 'input-mandatory-warning';
                    }
                }
                return contactsString;
            }

            if (!$scope.data.actionAdd && !$scope.data.actionChange && !$scope.data.actionRemove) {
                validationError = true;
                $scope.data.actionCheckboxesClass = 'input-mandatory-warning';
            }

            if ($scope.data.actionAdd) {
                $scope.data.addContactsString = stringifyContactsArray($scope.input.addContacts, true);
            }
            if ($scope.data.actionChange) {
                $scope.data.changeContactsString = stringifyContactsArray($scope.input.changeContacts, true);
            }
            if ($scope.data.actionRemove) {
                $scope.data.removeContactsString = stringifyContactsArray($scope.input.removeContacts, false);
            }

            if (validationError) {
                alertService.add('error', $translate('CONTACT.mandatory_error'));
            } else {
                var templateSupport = $templateCache.get('jpb/contact-forms/mail-templates/ap-new.support.txt');
                var templateCustomer = $templateCache.get('jpb/contact-forms/mail-templates/ap-new.customer.txt');
                var templateSubject = $templateCache.get('jpb/contact-forms/mail-templates/ap-new.subject.txt');

                var textSupport = $interpolate(templateSupport)($scope.data);
                var textCustomer = $interpolate(templateCustomer)($scope.data);
                var subject = $interpolate(templateSubject)($scope.data).trim();

                SupportService.create(subject, textSupport, subject, textCustomer).then(function () {
                    close();
                    alertService.add('info', $translate('CONTACT.inquiry_success'));
                });
            }
        };

    });
