angular.module('quattro.domain.view', [
    'ngRoute',
    'quattro.model',
    'quattro.domain.widget_info',
    'quattro.email.widget_emaillist',
    'quattro.ftp.widget_ftplist',
])
    .controller('DomainCtrl', function ($scope, $routeParams, $location, Domain) {

        var domain = $routeParams.domain;

        // to be used locally for submodules
        $scope.domain_name = domain;

        var model = new Domain({
            domain: domain
        });
        model.get();

        $scope.data = model.data;

        $scope.reload = function () {
            model.get();
        };

        $scope.edit = function (section) {
            $location.path('/domain=' + domain + '/edit' + (section ? '/' + section : ''));
        };

        $scope.emailSearch = {};
        $scope.ftpSearch = {};
    });
