angular
    .module('quattro.account.modal_edit', [
        'quattro.model',
        'quattro.account.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Account', 'myModalService', function ($rootScope, Account, myModalService) {

        $rootScope.$on('vq.account.edit', function (event, account) {

            // reflect modal state on account's scope
            account.$active = true;

            var model = new Account(account);
            model.get();

            myModalService.showModal({
                templateUrl: 'jpb/account/modal_edit.html',
                controller: 'ModalEditAccountController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    account.$active = false;
                });
            });

        });
    }])
    .controller('ModalEditAccountController', function ($scope, close, model, alertService) {

        $scope.data = model.data;

        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Account gespeichert.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Account konnte nicht gespeichert werden.');
            });
        };
    });
