angular.module('quattro.config', [
    'quattro.core.config',
])
    .constant('jpb_default_fees', [
        {
            label: 'ACCOUNT.Nichtverdiener',
            value: '7',
        },
        {
            label: 'ACCOUNT.Verdiener',
            value: '10',
        },
        {
            label: 'ACCOUNT.kleine Organisation',
            value: '10',
        },
        {
            label: 'ACCOUNT.grosse Organisation',
            value: '12',
        },
    ])
    .constant('jpb_states', [
        {
            label: 'aktiv',
            value: 'aktiv'
        },
        {
            label: 'nopop',
            value: 'nopop'
        },
        {
            label: 'disabled',
            value: 'disabled'
        },
        {
            label: 'deleted',
            value: 'deleted'
        },
    ])
    .constant('jpb_domain_states', [
        {
            label: 'registriert',
            value: 'registriert'
        },
        {
            label: 'aktiv',
            value: 'aktiv'
        },
        {
            label: 'nopop',
            value: 'nopop'
        },
        {
            label: 'disabled',
            value: 'disabled'
        },
        {
            label: 'deleted',
            value: 'deleted'
        },
    ])
    .constant('domain_types', [
        'JPBERLIN-PHP83',
        'JPBERLIN-PHP82',
        'JPBERLIN-PHP81',
        'SPAMFILTERDOMAIN',
        'REDIRECT',
    ])
    // users can't change their php-version at the moment. see SD-256
    .constant('jpb_user_selectable_domain_types', [
    ])

    // there are only clusters  JPBERLIN-PHP83', JPBERLIN-PHP82', JPBERLIN-PHP81', 'SPAMFILTERDOMAIN','REDIRECT'
    // but old versions stay on blacklist
    .constant('jpb_blacklisted_domain_types', [
        'JPBERLIN-PHP80',
        'JPBERLIN-PHP73',
        'JPBERLIN-PHP70',
        'JPBERLIN-PHP55',
        'JPBERLIN-PHP53',
        'JPBERLIN-PHP53-MIGRATE',
    ])
    // domain types for which letsencrypt is available
    .constant('letsencrypt_types', [
        'JPBERLIN-PHP83',
        'JPBERLIN-PHP82',
        'JPBERLIN-PHP81',
        'REDIRECT',
    ])
    .constant('account_type', 'JPBERLIN');
