angular.module('quattro.email.view', [
    'quattro.email.widget_info',
    'quattro.email.widget_forwardlist',
    'quattro.forward.modal_add',
    'ngRoute',
])
    .controller('EmailCtrl', function ($scope, $routeParams, $location, myModalService, Mail) {

        var domain = $routeParams.domain;
        var mailName = $routeParams.email || '';
        var mail = mailName + '@' + domain;

        $scope.forwardlist = [];
        $scope.reload = function () {
            model.get()
                .then(function (model) {
                    $scope.forwardlist = model.data.forwards.map(function (forward) {
                        return {
                            address: forward
                        };
                    });
                });
        };

        var model = new Mail({
            domain: domain,
            mail: mail
        });
        $scope.data = model.data;
        $scope.reload();

        $scope.edit = function () {
            $location.path('/domain=' + domain + '/email=' + mailName + '/edit');
        };

        $scope.forwardSearch = {};

        // FIXME: needed inside fowardlist directive, which doesnt have access
        // to model.
        $scope.modalAddForward = function () {
            $scope.$emit('vq.forward.add', model, function () {
                $scope.reload();
            });
        };

        $scope.removeForwards = model.removeForwards;
    });
