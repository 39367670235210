angular
    .module('quattro.jpb.contact-forms.domain-delete', [
        'quattro.core.support',
        'quattro.core.modal-confirm',
        'quattro.jpb.model.domain',
    ])
    .run(function ($rootScope, myModalService) {

        $rootScope.$on('vq.contact.domain-delete', function (event, account) {

            myModalService.showModal({
                templateUrl: 'jpb/contact-forms/modal-contact-domain-delete.html',
                controller: 'ContactFormDomainDeleteController',
                controllerAs: 'vm',
                inputs: {
                    account: account,
                }
            });

        });
    })
    .controller('ContactFormDomainDeleteController', function ($interpolate, $templateCache, close, $scope, account, Domain, SupportService, alertService, $translate) {

        $scope.domains = [];
        Domain.query(account).then(function (result) {
            $scope.domains = result;
        });

        $scope.data = {
            account: account,
            // default values for radio boxes
            del_method: 'terminate',
            terminate: 'asap',
            eliminate: 'immediate',
        };

        this.cancel = close;

        this.submit = function () {
            $scope.data.domain_class = '';
            if (!$scope.data.domain) {
                $scope.data.domain_class = 'input-mandatory-warning';
                alertService.add('error', $translate('CONTACT.mandatory_error'));
            } else {
                var templateSupport = $templateCache.get('jpb/contact-forms/mail-templates/domain-delete.support.txt');
                var templateCustomer = $templateCache.get('jpb/contact-forms/mail-templates/domain-delete.customer.txt');
                var templateSubject = $templateCache.get('jpb/contact-forms/mail-templates/domain-delete.subject.txt');

                var textSupport = $interpolate(templateSupport)($scope.data);
                var textCustomer = $interpolate(templateCustomer)($scope.data);
                var subject = $interpolate(templateSubject)($scope.data).trim();

                SupportService.create(subject, textSupport, subject, textCustomer).then(function () {
                    close();
                    alertService.add('info', $translate('CONTACT.inquiry_success'));
                });
            }
        };

    });
