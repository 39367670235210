angular.module('quattro.directives', [
    'quattro.core.hpls_api_1',
    'quattro.core.password-form',
    'quattro.core.password-veil',
])
    .run(function ($rootScope) {

        // text for hint box
        $rootScope.getHintText = function (id) {
            return 'Missing hint text for: ' + id;
        };

    })
    .directive('notes', function () {
        return {
            templateUrl: 'jpb/shared/notes.html',
            controller: function ($scope) {

            }
        };
    })
    // validate form fields w/ api when `ngModel` is backed by proper model and
    // floating field view.
    .directive("ngModel", ['$q', 'api', function ($q, api) {

        // inject error message into designer structure
        function setError(inputElement, error) {

            var floatField = inputElement.closest('.float-field');
            var floatMessage = floatField.find('.float-message').first();

            if (error === undefined) {
                floatField.removeClass('message negative');
                floatMessage.text('');
                return;
            }

            api.translate(error)
                .then(function (text) {
                    floatField.addClass('message negative');
                    floatMessage.text(text);
                });
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attributes, controller) {

                var prefixedName = element.attr('ng-model');
                // FIXME: don't hardcode `data`, could be taken from `prefixedName`
                var name = prefixedName.replace(/^data\./, '');

                // mayhap find better way to access model's validate
                // we may access parent controller by depending on them
                // at least make sure, `ng-model` is backed by proper model

                // `scope.data` not yet initialized in all cases, e.g. modals
                var data;

                controller.$asyncValidators.api = function (modelValue, viewValue) {

                    // at this point `scope.data` is set;
                    data = scope.data;

                    // default to `valid` if no api validation can be used
                    if (!data || !data.$validate) {
                        // console.info('no validator found.');
                        return $q.when();
                    }

                    var validation = $q.defer();

                    var isValid = data.$validate(name, viewValue);
                    isValid.then(function () {
                        setError(element, undefined);
                        validation.resolve();
                    }, function (error) {
                        setError(element, error);
                        validation.reject();
                    });

                    return validation.promise;
                };

            },
        };
    }])
    .directive('hint', function (hintService) {
        // display box for hint texts

        return {
            restrict: 'E',  // element
            templateUrl: 'jpb/shared/hint.html',
            scope: {},
            controller: function ($scope) {

                $scope.hintService = hintService;
                $scope.hintService.setText = function setText(text) {
                    // wrap in `$apply` helps ng update its views
                    $scope.$apply(function () {
                        hintService.text = text;
                    });
                };
            },
        };
    })
    .directive('hint', function (hintService) {

        // displays hint message of this elements' `ng-model` on mouseover/out in hint element.
        // if this element has no `ng-model` the first descendant w/ `ng-model` attribute is used.
        // you have to include `hint` element somewhere in dom.
        //
        // usage:
        // ```
        // <input type="text" ng-model="firstName" hint />
        // <!-- or -->
        // <label hint>
        //   some label
        //   <input type="text" ng-mogel="secondName" />
        // </label>
        // ```
        //
        // text is fetched from current scope, in the examples that would be
        // `scope.getHintText('firstName')` and `scope.getHintText('secondName')`.

        return {
            restrict: 'A',  // attribute
            //require: 'ngModel', // expose ngModelController as `controller`
            link: function (scope, element, attributes, controller) {

                var ngModelElement = element.is('[ng-model]') ? element : element.find('[ng-model]');
                var ngModelName = ngModelElement.attr('ng-model');

                // `scope.getHintText` not yet avail, it will be there if callbacks are called.

                element.on('mouseover', function () {
                    var text = scope.getHintText(ngModelName);
                    hintService.setText && hintService.setText(text);
                });
                element.on('mouseout', function () {
                    hintService.setText && hintService.setText('');
                });
            },
        };
    })
    .directive('searchbox', ['$location', 'userService', function ($location, userService) {
        return {
            restrict: 'E',
            templateUrl: 'jpb/shared/search_box.html',
            controller: function ($scope) {

                $scope.submit = function () {

                    var userLevel = userService.get('userLevel');

                    // account or domain name
                    var user = userService.get('userName');

                    var url = [
                        'search',
                        userLevel === 'admin' ? 'admin' : (userLevel + '/' + user),
                        $scope.searchService.query
                    ].join('/');
                    $location.path(url);
                };

                $scope.reset = function () {
                    $scope.searchService.query = '';
                };
            }
        };
    }]);
