angular
    .module('quattro.email.vacation', [])
    .directive('vacationEdit', function () {
        return {
            scope: {
                data: '=',
            },
            restrict: 'E',
            templateUrl: 'jpb/email/form_vacation.html',
            controller: ['$scope', function ($scope) {

                $scope.clearStartDate = function () {
                    $scope.data.vac_date_start = '';
                };

                $scope.clearEndDate = function () {
                    $scope.data.vac_date_end = '';
                };
            }],
        };
    });
