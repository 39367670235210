angular.module('quattro.jpb.incident.edit', [
    'ngRoute',
    'quattro.model',
    'quattro.core.alert.service',
])
    .controller('EditIncidentController', function ($scope, alertService, $location, Incident) {

        var incident = new Incident({});
        incident.get();

        $scope.data = incident.data;

        $scope.cancel = function () {
            $location.path('/dashboard');
        };

        $scope.save = function () {
            incident.save().then(function () {
                // in case alertService magically starts to work someday it's used here () however atm it seems not to work)
                alertService.add('success', 'Incident has been saved.');
                // redirect to dashboard
                $location.path('/dashboard');
            }, function () {
                // alertService: see above
                alertService.add('error', 'Incident ' + incident + ' konnte nicht vollständig gespeichert werden.');
            });
        };
    });
