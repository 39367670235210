angular
    .module('quattro.core.generatePassword', [])
    .factory('generatePassword', function () {

        var generatePassword = function (length) {

            // password length defaults to 12
            length = length || 12;

            var alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
            var alphabetLength = alphabet.length;

            var pass = [];
            for (var x = 0; x < length - 2; x++) {
                var i = Math.floor(Math.random() * alphabetLength);
                pass[x] = alphabet.charAt(i);
            }
            pass.push(Math.floor(Math.random() * 10));
            pass.push(Math.floor(Math.random() * 10));

            pass.sort(function () {
                return 0.5 - Math.random();
            });
            return pass.join('');
        };

        return generatePassword;
    });
