angular.module('quattro.core.config', [])
    .constant('account_anrede', [
        {
            label: 'ACCOUNT.Herr',
            value: 'herr'
        },
        {
            label: 'ACCOUNT.Frau',
            value: 'frau'
        },
        {
            label: 'ACCOUNT.unknown',
            value: 'unknown'
        }
    ])
    .constant('invoice_types', [
        {
            label: 'ACCOUNT.invoice_type_mail',
            value: 'mail'
        },
        {
            label: 'ACCOUNT.invoice_type_post',
            value: 'post'
        }
    ])
    .constant('payment_types', [
        {
            label: 'ACCOUNT.payment_type_invoice',
            value: 'invoice'
        },
        {
            label: 'ACCOUNT.payment_type_dta',
            value: 'dta'
        }
    ])
    .constant('suggested_countries', [
        'DE',
        'AT',
        'CH',
    ])
    .constant('countries', [
        "AF", "EG", "AX", "AL", "DZ", "UM", "AS", "VI", "AD", "AO", "AI", "AQ", "AG", "GQ", "AR", "AM", "AW", "AC", "AZ", "ET", "AU", "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BA", "BW", "BR", "VG", "IO", "BN", "BG", "BF", "BI", "EA", "CL", "CN", "CK", "CR", "CI", "CW", "DK", "KP", "DE", "DG", "DM", "DO", "DJ", "EC", "SV", "ER", "EE", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE", "GH", "GI", "GD", "GR", "GL", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT", "HN", "IN", "ID", "IQ", "IR", "IE", "IS", "IM", "IL", "IT", "JM", "JP", "YE", "JE", "JO", "KY", "KH", "CM", "CA", "IC", "CV", "BQ", "KZ", "QA", "KE", "KG", "KI", "CC", "CO", "KM", "CG", "CD", "XK", "HR", "CU", "KW", "LA", "LS", "LV", "LB", "LR", "LY", "LI", "LT", "LU", "MG", "MW", "MY", "MV", "ML", "MT", "MA", "MH", "MQ", "MR", "MU", "YT", "MK", "MX", "FM", "MC", "MN", "ME", "MS", "MZ", "MM", "NA", "NR", "NP", "NC", "NZ", "NI", "NL", "NE", "NG", "NU", "MP", "NF", "NO", "OM", "AT", "PK", "PS", "PW", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "KR", "MD", "RE", "RW", "RO", "RU", "SB", "ZM", "WS", "SM", "ST", "SA", "SE", "CH", "SN", "RS", "SC", "SL", "ZW", "SG", "SX", "SK", "SI", "SO", "MO", "HK", "ES", "LK", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "ZA", "SD", "GS", "SS", "SR", "SJ", "SZ", "SY", "TJ", "TW", "TZ", "TH", "TL", "TG", "TK", "TO", "TT", "TA", "TD", "CZ", "TN", "TR", "TM", "TC", "TV", "UG", "UA", "HU", "UY", "UZ", "VU", "VA", "VE", "AE", "US", "UK", "VN", "WF", "CX", "EH", "CF", "CY"
    ]);
