angular
    .module('quattro.jpb.contact-forms.account-delete', [
        'quattro.core.support',
        'quattro.core.modal-confirm',
    ])
    .run(function ($rootScope, myModalService) {

        $rootScope.$on('vq.contact.account-delete', function (event, account) {

            myModalService.showModal({
                templateUrl: 'jpb/contact-forms/modal-contact-account-delete.html',
                controller: 'ContactFormAccountDeleteController',
                controllerAs: 'vm',
                inputs: {
                    account: account,
                }
            });

        });
    })
    .controller('ContactFormAccountDeleteController', function ($interpolate, $templateCache, close, $scope, account, SupportService, alertService, $translate) {

        $scope.data = {
            account: account,
            activate: 'asap',
        };

        this.cancel = close;

        this.submit = function () {
            // reset for re-validation
            $scope.data.delete_class = '';
            $scope.data.activate_class = '';
            $scope.data.delete_data_mail_class = '';
            $scope.data.delete_backup_class = '';

            var validationError = false;

            if (!$scope.data.delete) {
                $scope.data.delete_class = 'input-mandatory-warning';
                validationError = true;
            }
            if (!$scope.data.activate) {
                $scope.data.activate_class = 'input-mandatory-warning';
                validationError = true;
            }
            if ($scope.data.delete_data && !$scope.data.delete_data_mail) {
                $scope.data.delete_data_mail_class = 'input-mandatory-warning';
                validationError = true;
            }
            if (!$scope.data.delete_backup) {
                $scope.data.delete_backup_class = 'input-mandatory-warning';
                validationError = true;
            }

            if (validationError) {
                alertService.add('error', $translate('CONTACT.mandatory_error'));
            } else {
                var templateSupport = $templateCache.get('jpb/contact-forms/mail-templates/account-delete.support.txt');
                var templateCustomer = $templateCache.get('jpb/contact-forms/mail-templates/account-delete.customer.txt');
                var templateSubject = $templateCache.get('jpb/contact-forms/mail-templates/account-delete.subject.txt');

                var textSupport = $interpolate(templateSupport)($scope.data);
                var textCustomer = $interpolate(templateCustomer)($scope.data);
                var subject = $interpolate(templateSubject)($scope.data).trim();

                SupportService.create(subject, textSupport, subject, textCustomer).then(function () {
                    close();
                    alertService.add('info', $translate('CONTACT.inquiry_success'));
                });
            }
        };

    });
