angular
    .module('quattro.domain.edit', [
        'ngRoute',
        'quattro.model',
        'quattro.domain.form',
        'quattro.core.alert.service',
    ])
    .controller('EditDomainCtrl', function ($anchorScroll, $scope, alertService, $location, $routeParams, Domain, $window) {

        var domain = $routeParams.domain;
        var section = $routeParams.section;

        $window.setTimeout(function () {
            $anchorScroll(section);
        }, 1);

        var model = new Domain({
            domain: domain
        });
        model.get();

        $scope.data = model.data;


        $scope.cancel = function () {
            $location.path('/domain=' + domain);
        };

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Domain ' + domain + ' wurde gespeichert.');
                $location.path('/domain=' + domain);
            }, function (reason) {
                alertService.add('error', 'Domain ' + domain + ' konnte nicht vollständig gespeichert werden.');
            });
        };
    });
