angular
    .module('quattro.mailinglist.modal_edit', [
        'quattro.model',
        'quattro.mailinglist.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Mailinglist', 'myModalService', function ($rootScope, Mailinglist, myModalService) {

        $rootScope.$on('vq.mailinglist.edit', function (event, mailinglist) {

            // reflect modal state on mailinglist's scope
            mailinglist.$active = true;

            var model = new Mailinglist(mailinglist);
            model.get();

            myModalService.showModal({
                templateUrl: 'jpb/mailinglist/modal_edit.html',
                controller: 'ModalEditMailinglistController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    mailinglist.$active = false;
                });
            });

        });
    }])
    .controller('ModalEditMailinglistController', function ($scope, close, alertService, model) {

        $scope.data = model.data;


        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Mailinglist gespeichert.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Mailinglist konnte nicht gespeichert werden.');
            });
        };
    });
