angular
    .module('quattro.core.password-veil', [])
    .directive('quattroPasswordVeil', function () {

        // display placeholder.
        // actual value will be shown on mouseover until `interval` seconds
        // after mouseout.

        return {
            restrict: 'E',
            scope: {
                'value': '@',
            },
            controller: 'quattroPasswordVeilController',
            controllerAs: 'vm',
            templateUrl: 'core/password/quattro_password_veil.html',
        };
    })
    .controller('quattroPasswordVeilController', function ($scope, $window) {

        var interval = 5 * 1000;   // in milliseconds
        var timeout;

        this.veil = true;

        this.show = function () {
            this.veil = false;
            if (timeout) {
                $window.clearTimeout(timeout);
                timeout = undefined;
            }
        };

        this.hide = function () {
            this.veil = true;
        };

        this.hideInASec = function () {
            if (timeout) {
                return;
            }
            timeout = $window.setTimeout(function () {
                $scope.$apply(this.hide());
                timeout = undefined;
            }.bind(this), interval);
        };

    });
