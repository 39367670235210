angular
    .module('quattro.core.tools', [])
    .factory('curryIt', function () {

        // https://www.sitepoint.com/currying-in-functional-javascript/
        return function curryIt(uncurried) {
            var parameters = Array.prototype.slice.call(arguments, 1);
            return function () {
                return uncurried.apply(this, parameters.concat(
                    Array.prototype.slice.call(arguments, 0)
                ));
            };
        };
    })
    .factory('allTrue', function () {

        // returns `true` if all items in array are trueish.
        return function allTrue(array) {

            return array.reduce(function (prev, curr, index) {
                return !!(prev && curr);
            }, true);
        };
    })
    .factory('resolvePath', function () {
        return function resolvePath(path) {
            return path.split('.').reduce(function (prev, cur) {
                return prev ? prev[cur] : undefined;
            }, this);
        };
    });

