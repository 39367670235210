angular.module('quattro.jpb.dashboard', [
    'quattro.core.user',
    'quattro.jpb.contact-forms.ap-change',
    'quattro.jpb.contact-forms.ap-new',
    'quattro.jpb.contact-forms.account-delete',
    'quattro.jpb.contact-forms.domain-new',
    'quattro.jpb.contact-forms.domain-change',
    'quattro.jpb.contact-forms.domain-delete',
    'quattro.jpb.contact-forms.cert-new',
    'quattro.jpb.contact-forms.db-new',
    'quattro.jpb.contact-forms.db-delete',
    'quattro.jpb.contact-forms.db-reset',
    'quattro.jpb.contact-forms.invoice-clearance',
    'quattro.jpb.contact-forms.general',
])
    .controller('DashboardController', function (userService, $scope, api) {

        var level = userService.get('userLevel');
        var account = level === 'account' && userService.get('userName');

        $scope.dashboardReady = false;
        $scope.account = account;
        $scope.accountType = '';
        $scope.accountIsJPBerlin = false;
        $scope.accountIsHeinlein = false;
        $scope.accountIsEuropostfach = false;

        api.single({
            method: 'q.dashboard.get',
            params: {
                account: account,
            }
        }).then(function (resp) {
            $scope.accountType = resp.type;
            if ($scope.accountType === 'JPBERLIN') {
                $scope.accountIsJPBerlin = true;
            } else if ($scope.accountType === 'HEINLEIN-SUPPORT') {
                $scope.accountIsHeinlein = true;
            } else if ($scope.accountType === 'EUROPOSTFACH') {
                $scope.accountIsEuropostfach = true;
            }
            $scope.dashboardReady = true;
            // Attention: Notification(s) will be wrapped in a <pre> tag in the template/frontend (dashboard.html)
            // This is just a temporary solution due to angular escaping html tags in frontend markers,
            // but to allow line breaks in notifications.
            $scope.notification = resp.global_notification && resp.global_notification.trim() !== '' ?
                resp.global_notification.trim() : false;
        });

        $scope.submenusOpen = {
            account: 'closed',
            domain: 'closed',
            db: 'closed',
            mailinglist: 'closed',
        };

        this.toggleSubmenu = function (menu) {
            $scope.submenusOpen[menu] = ($scope.submenusOpen[menu] === 'open') ? 'closed' : 'open';
        };

        this.openContactForm = function (formname) {
            $scope.$emit('vq.contact.' + formname, account);
        };

    });
