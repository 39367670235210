angular
    .module('quattro.core.form-helper.checkval', [])
    .directive("ngModel", ['$window', function ($window) {
        // extend built-in `ng-model`
        // trigger 'checkval' event on element if value was changed, that's
        // where designer js takes over.
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                // VQ-414: force `checkval` on init, when `$watch` doesn't work
                $window.setTimeout(function () {
                    var value = element.val();
                    if (!value || value === '?') {
                        return;
                    }
                    element.trigger('checkval');
                }, 1);
                scope.$watch(attr.ngModel, function () {
                    element.trigger('checkval');
                });
            }
        };
    }]);
