angular
    .module('quattro.services', [
        'quattro.core.viewhelper'
    ]);


angular.module('quattro.services').factory('hintService', function () {

    // storage for current hint text

    var text = '';

    return {
        text: text
    };
});
