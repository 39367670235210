angular
    .module('quattro.mailinglist.widget_mailinglistlist', [
        'ngRoute',
        'quattro.model',
        'quattro.core.modal-confirm',
        'quattro.mailinglist.modal_add',
        'quattro.mailinglist.modal_edit',
    ])
    .directive('mailinglistlist', function () {
        return {
            templateUrl: 'jpb/mailinglist/widget_mailinglistlist.html',
            controller: ['$scope', '$log', 'Mailinglist', 'myModalService', '$q', function ($scope, $log, Mailinglist, myModalService, $q) {

                var account = $scope.userName;

                $scope.modalAddMailinglist = function () {
                    $scope.$emit('vq.mailinglist.add', {account: account,}, function () {
                        $scope.loadMailinglistList();
                    });
                };

                $scope.quickEditMailinglist = function (mailinglist) {
                    $scope.$emit('vq.mailinglist.edit', mailinglist);
                };

                $scope.loadMailinglistList = function () {
                    var promise = Mailinglist.query(account);
                    promise.then(function (result) {
                        $scope.mailinglistlist = result;
                    });
                };

                $scope.loadMailinglistList();

                function deleteMailinglistList(mailinglists) {
                    var promisePool = mailinglists.map(function (mailinglist) {
                        return new Mailinglist({
                            account: account,
                            mailinglist: mailinglist,
                        }).remove();
                    });
                    return $q.all(promisePool);
                }

                $scope.deleteSelected = function () {
                    var list = this.mailinglistlistSelected().map(function (value) {
                        return value.mailinglist;
                    });

                    myModalService.showModal({
                        templateUrl: 'jpb/mailinglist/modal-confirm-delete.html',
                        inputs: {
                            list: list,
                            reload: this.loadMailinglistList,
                        },
                        controller: function ($scope, close, list, reload) {
                            $scope.list = list;
                            $scope.cancel = function () {
                                close();
                            };
                            $scope.confirm = function () {
                                close();
                                deleteMailinglistList(list)
                                    .finally(function () {
                                        reload();
                                        angular.element('.itemlist').removeClass('show-contextual');
                                    });
                            };
                        },
                    });
                };

                $scope.orderMlByExpression = 'mailinglist';
                $scope.orderMlByReverse = false;

                $scope.orderMlBy = function (predicate) {
                    $scope.orderMlByReverse = ($scope.orderMlByExpression === predicate) ? !$scope.orderMlByReverse : false;
                    $scope.orderMlByExpression = predicate;
                };
                $scope.orderedMlClass = function (predicate) {
                    if ($scope.orderMlByExpression === predicate) {
                        return $scope.orderMlByReverse ? 'sort-desc' : 'sort-asc';
                    }
                    return '';
                };


                $scope.mailinglistlistSelected = function () {
                    var list = $scope.mailinglistlist;
                    var filtered = list.filter(function (item) {
                        return item.$checked;
                    });
                    return filtered.length ? filtered : list;
                };
            }]
        };
    });
