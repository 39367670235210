angular
    .module('quattro.core.info-memo', [])
    .directive('quattroInfoMemo', [function () {
        return {
            restrict: 'E',
            scope: {
                content: '@',
            },
            link: function (scope, element, attributes) {

                var textarea = element.find('textarea');

                scope.$watch('content', function () {
                    var height = element.find('textarea').get(0).scrollHeight;
                    textarea.css('min-height', (height <= 200 ? height : 200) + 'px');
                });
            },
            template: [
                '<textarea readonly>',
                '{{content}}',
                '</textarea>',
            ].join(''),
        };
    }]);
