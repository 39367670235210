angular.module('quattro.jpb.login.password-set', [
    'ngRoute',
    'quattro.core.alert.service',
    'quattro.core.hpls_api_1',
    'quattro.core.form-helper.checkval',
    'quattro.core.password-form',
])
    .controller('JpbPasswordSetController', function ($routeParams, $q, api, alertService) {

        this.token = $routeParams.token;

        this.sent = false;

        this.validatePassword = function (value) {
            if (!value)
                return $q.when();
            return api.single('password.set', {password: value, validate_only: true}).then(angular.noop)
        };

        this.save = function () {

            api
                .single('password.set', {token: this.token, password: this.password})
                .then(this.handleSuccess.bind(this), this.handleError)
        };

        this.handleSuccess = function () {
            this.sent = true
        }

        this.handleError = function (error) {
            alertService.add('error', api.translate(error))
        }
    });
