angular
    .module('quattro.domain.modal_edit', [
        'quattro.model',
        'quattro.domain.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Domain', 'myModalService', function ($rootScope, Domain, myModalService) {

        $rootScope.$on('vq.domain.edit', function (event, domain) {

            // reflect modal state on domain's scope
            domain.$active = true;

            var model = new Domain(domain);
            model.get();

            myModalService.showModal({
                templateUrl: 'jpb/domain/modal_edit.html',
                controller: 'ModalEditDomainController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    domain.$active = false;
                });
            });

        });
    }])
    .controller('ModalEditDomainController', function ($scope, close, alertService, model) {

        $scope.data = model.data;


        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Domain gespeichert.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Domain konnte nicht gespeichert werden.');
            });
        };
    });
