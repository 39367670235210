angular.module('quattro.search', ['ngRoute', 'quattro.directives'])
    .controller('SearchCtrl', ['$scope', '$routeParams', 'api', 'userService', 'searchService', function ($scope, $routeParams, api, userService, searchService) {

        // custom events will trigger modal edit

        $scope.editAccount = function (account) {
            $scope.$emit('vq.account.edit', account);
        };
        $scope.editMailinglist = function (mailinglist) {
            $scope.$emit('vq.mailinglist.edit', mailinglist);
        };
        $scope.editDomain = function (domain) {
            $scope.$emit('vq.domain.edit', domain);
        };
        $scope.editMail = function (mail) {
            $scope.$emit('vq.mail.edit', mail);
        };
        $scope.editFtp = function (ftp) {
            $scope.$emit('vq.ftp.edit', ftp);
        };


        var level = $routeParams.level;
        var user = $routeParams.user;
        var query = $routeParams.query;

        // init global input field
        if ($scope.searchService) {
            $scope.searchService.query = query;
        }

        switch (level) {
            case 'admin':
                searchService.adminSearch(query);
                break;
            case 'account':
                searchService.accountSearch(user, query);
                break;
            case 'mailinglist':
                searchService.mailinglistSearch(user, query);
                break;
            case 'domain':
                searchService.domainSearch(user, query);
                break;
        }

    }]);
