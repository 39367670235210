angular.module('quattro.mailinglist.view', [
    'ngRoute',
    'quattro.model',
    'quattro.mailinglist.widget_info'
])
    .controller('MailinglistCtrl', function ($scope, $routeParams, $location, Mailinglist) {

        var account = $routeParams.account;
        var mailinglist = $routeParams.mailinglist;

        var model = new Mailinglist({
            mailinglist: mailinglist,
            account: account,
        });
        model.get();

        $scope.account = account;
        $scope.data = model.data;

        $scope.reload = function () {
            model.get();
        };

        $scope.edit = function (section) {
            $location.path('/account=' + account + '/mailinglist=' + mailinglist + '/edit' + (section ? '/' + section : ''));
        };
    });
