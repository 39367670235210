angular
    .module('quattro.forward.modal_add', [
        'quattro.forward.widget_edit',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'myModalService', function ($rootScope, myModalService) {

        $rootScope.$on('vq.forward.add', function (event, email, callback) {

            myModalService.showModal({
                templateUrl: 'jpb/forward/modal_add.html',
                controller: 'ModalAddForwardController',
                inputs: {
                    model: email,
                },
            }).then(function (modal) {
                modal.close.then(function () {
                    callback && callback();
                });
            });
        });
    }])
    .controller('ModalAddForwardController', function ($scope, close, model, alertService, viewHelper) {

        $scope.new_forwards = [];

        $scope.forward_widget = {};


        $scope.validateForward = function (value) {
            return model.data.$validate('forwards', [value]);
        };

        $scope.validateInboxsave = function (value) {
            return model.data.$validate('inboxsave', value);
        };


        $scope.data = model.data;


        $scope.cancel = close;

        $scope.save = function () {

            if (!viewHelper.formIsValid()) {
                return;
            }

            // include current input field content
            $scope.forward_widget.flush();

            // add new addresses to model's `forwards`
            $scope.new_forwards.forEach(function (item) {
                if (model.data.forwards.indexOf(item) === -1) {
                    model.data.forwards.push(item);
                }
            });

            model.update()
                .then(function () {
                    alertService.add('success', 'Weiterleitungen wurden angelegt.');
                    close({
                        model: model,
                    });
                }, function () {
                    alertService.add('error', 'Weiterleitungen konnten nicht vollständig angelegt werden.');
                });
        };
    });
