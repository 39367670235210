angular.module('quattro.jpb.model.mailinglist', [
    'quattro.core.model.prototype',
])
    .factory('Mailinglist', function ($log, api, Model) {

        var prototype = Model.prototype;

        function getDefaultData() {
            return {
                mailinglist: undefined,
                domain: 'listi.jpberlin.de',
                status: undefined,
                password: undefined,
                adminmail: undefined,
            };
        }

        var authorization = {
            read: 'all',
            create: ['admin', 'account'],
            update: {
                default: ['admin', 'account'],
                attributes: {}
            },
            remove: ['admin', 'account'],
        };


        var scenarios = {
            read: [
                {
                    method: 'q.mailinglist.get',
                    params: {
                        mailinglist: {
                            primary_key: true,
                        },
                        account: {
                            required: true,
                        },
                    },
                    results: {
                        mailinglist: null,
                        domain: null,
                        status: null,
                        password: null,
                        adminmail: null,
                    }
                }
            ],
            create: [
                {
                    method: 'q.account.mailinglist.add',
                    params: {
                        account: {
                            primary_key: true,
                        },
                        mailinglist: {
                            required: true,
                        },
                        domain: {
                            required: true,
                        },
                        password: {
                            required: true,
                        },
                        status: null,
                        adminmail: null,
                    }
                }
            ],
            update: [
                {
                    method: 'q.mailinglist.set',
                    params: {
                        account: {
                            primary_key: true,
                        },
                        mailinglist: {
                            primary_key: true,
                        },
                        status: null,
                        password: null,
                        domain: null,
                        adminmail: null,
                    },
                },
            ],
            remove: [
                {
                    method: 'q.account.mailinglist.del',
                    params: {
                        account: {
                            primary_key: true,
                        },
                        mailinglist: {
                            primary_key: true,
                        },
                    },
                },
            ],
        };

        function query(account) {
            return api.single('q.account.mailinglist.list', {
                account: account,
            }).then(function (result) {
                result.forEach(function (mailinglist) {
                    mailinglist.account = account;
                });
                return result;
            }, function (error) {
                $log.error('mailinglist.list', error.code, error.message);
                return error;
            });
        }

        function Mailinglist(config, scenario) {
            this.scenario = scenario;
            var data = scenario === 'create' ? getDefaultData() : {};
            this.data = angular.merge(data, config);
            this.data.$validate = this.validate.bind(this);
        }

        // static
        Mailinglist.query = query;

        Mailinglist.prototype = angular.merge(new Model(), {
            scenarios: scenarios,
            authorization: authorization,
        });

        return Mailinglist;
    });
