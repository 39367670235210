angular
    .module('quattro.email.form', [
        'quattro.core.viewhelper',
        'quattro.directives',
        'quattro.email.vacation',
        'quattro.forward.widget_edit',
        'quattro.core.password-form',
    ])
    .directive('quattroEmailForm', [function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                isCreate: '=?',
                allowCatchall: '=?',
                cancel: '=',
                // will be called after successfull validation
                save: '=',
            },
            controller: 'emailFormController',
            controllerAs: 'vm',
            templateUrl: 'jpb/email/widget_form.html',
        };
    }])
    .controller('emailFormController', [
        '$scope',
        'userService',
        'viewHelper',
        'jpb_states',
        function ($scope, userService, viewHelper, jpb_states) {


            $scope.states = jpb_states;

            $scope.forward_widget = {};

            $scope.validateForward = function (value) {
                return $scope.data.$validate('forwards', [value]);
            };

            $scope.validateInboxsave = function (value) {
                return $scope.data.$validate('inboxsave', value);
            };


            this.user = userService.scope;

            this.cancel = $scope.cancel;

            this.save = function () {

                if (angular.isFunction($scope.forward_widget.flush)) {
                    $scope.forward_widget.flush();
                }

                // ngForm.$validate();

                if (!viewHelper.formIsValid()) {
                    return;
                }

                $scope.save();
            };
        }]);
