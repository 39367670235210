angular
    .module('quattro.ftp.widget_ftplist', [
        'ngRoute',
        'quattro.core.filters',
        'quattro.core.modal-confirm',
        'quattro.jpb.model.ftp',
        'quattro.ftp.modal_add',
        'quattro.ftp.modal_edit',
    ])
    .directive('ftplist', function (Ftp) {
        return {
            templateUrl: 'jpb/ftp/widget_ftplist.html',
            controller: function ($scope, $routeParams, Ftp, myModalService, $q, $filter) {
                var domain = $routeParams.domain;

                // manages api calls to delete list pf ftps, returns promise
                function deleteFtpList(ftps) {
                    var promisePool = ftps.map(function (ftp) {
                        var domain = $filter('ftp_domain_part')(ftp);
                        return new Ftp({
                            domain: domain,
                            ftp: ftp,
                        }).remove();
                    });
                    return $q.all(promisePool);
                }


                $scope.modalAddFtp = function () {
                    $scope.$emit('vq.ftp.add', {domain: domain,}, function () {
                        $scope.loadFtpList();
                    });
                };

                $scope.quickEditFtp = function (ftp) {

                    // enforce consistence model naming, cover up api glitches
                    ftp.ftp = ftp.name;

                    // custom event, will trigger modal edit
                    // callback will be called after modal has closed
                    $scope.$emit('vq.ftp.edit', ftp, function () {
                        $scope.loadFtpList();
                    });
                };

                $scope.loadFtpList = function () {
                    var promise = Ftp.query(domain);
                    promise.then(function (result) {
                        $scope.ftplist = result;
                    });
                };

                $scope.loadFtpList();

                // currently 'emaillist' and 'ftplist' share the same scope.
                // sort related property names should be different. this is a
                // dirty hack, before anyone introduces proper namespaces, or a
                // custom sortable list widget.
                $scope.orderByExpressionF = 'name';
                $scope.orderByReverseF = false;

                $scope.orderByF = function (predicate) {
                    $scope.orderByReverseF = ($scope.orderByExpressionF === predicate) ? !$scope.orderByReverseF : false;
                    $scope.orderByExpressionF = predicate;
                };
                $scope.orderedClassF = function (predicate) {
                    if ($scope.orderByExpressionF === predicate) {
                        return $scope.orderByReverseF ? 'sort-desc' : 'sort-asc';
                    }
                    return '';
                };


                $scope.ftplistSelected = function () {
                    var list = $scope.ftplist;
                    var filtered = list.filter(function (item) {
                        return item.$checked;
                    });
                    return filtered.length ? filtered : list;
                };

                $scope.deleteSelectedFtps = function () {

                    var list = $scope.ftplistSelected().map(function (value) {
                        return value.name;
                    });

                    myModalService.showModal({
                        templateUrl: 'jpb/ftp/modal_confirm_delete.html',
                        inputs: {
                            list: list,
                            reload: $scope.loadFtpList,
                        },
                        controller: ['$scope', 'close', 'list', 'reload', function ($scope, close, list, reload) {
                            $scope.list = list;
                            $scope.cancel = function () {
                                close();
                            };
                            $scope.confirm = function () {
                                close();
                                deleteFtpList(list)
                                    .finally(function () {
                                        reload();
                                        angular.element('.itemlist').removeClass('show-contextual');
                                    });
                            };
                        }],
                    });
                };
            }
        };
    });
