angular
    .module('quattro.core.crumbs', [
        'quattro.core.hpls_api_1',
        'quattro.core.user',
    ])
    .factory('crumbService', ['$log', 'api', 'userService', function ($log, api, userService) {

        var scope = {};


        // return view name depending on hierarchy
        function getViewName(scope) {
            if (scope.ftp) {
                return scope.ftp;
            }
            if (scope.email) {
                return scope.email + '@' + scope.domain;
            }
            if (scope.domain) {
                return scope.domain;
            }
            if (scope.mailinglist) {
                return scope.mailinglist;
            }
            if (scope.account) {
                return scope.account;
            }
        }

        var setRouteParams = function (params, crumbClass) {

            params = params || {};

            var userName = userService.get('userName');
            var userLevel = userService.get('userLevel');

            // set breadcrumb data depending on url route data
            scope.account = params.account || '';
            scope.mailinglist = params.mailinglist || '';
            scope.domain = params.domain || '';
            scope.email = params.email || '';
            scope.ftp = params.ftp || '';

            // don't show domain for ftp and email users
            scope.show_domain = ['mail', 'ftp'].indexOf(userLevel) === -1;

            scope.className = crumbClass || '';
            scope.viewName = getViewName(scope);


            // account is not always part of url, in case of user levels 'account'
            // and 'admin' we want to show account breadcrumb anyway.

            if (!scope.account && userLevel === 'account') {
                scope.account = userName;
            }

            if (!scope.account && scope.mailinglist && userLevel === 'admin') {
                api.single('q.admin.owner.get', {
                    'mailinglist': scope.mailinglist
                })
                    .then(function (result) {
                        scope.account = result;
                    }, function (error) {
                        $log.error('q.admin.owner.get failed! code: ' + error.code + ' - message: ' + error.message);
                    });
            }

            if (!scope.account && scope.domain && userLevel === 'admin') {
                api.single('q.admin.owner.get', {
                    'domain': scope.domain
                })
                    .then(function (result) {
                        scope.account = result;
                    }, function (error) {
                        $log.error('q.admin.owner.get failed! code: ' + error.code + ' - message: ' + error.message);
                    });
            }
        };

        return {
            scope: scope,
            setRouteParams: setRouteParams,
        };
    }]);
