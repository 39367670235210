angular
    .module('quattro.ftp.modal_add', [
        'quattro.model',
        'quattro.ftp.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Ftp', 'myModalService', function ($rootScope, Ftp, myModalService) {

        $rootScope.$on('vq.ftp.add', function (event, ftp, callback) {

            var model = new Ftp(ftp, 'create');

            myModalService.showModal({
                templateUrl: 'jpb/ftp/modal_add.html',
                controller: 'ModalAddFtpController',
                inputs: {
                    model: model,
                },
            }).then(function (modal) {
                modal.close.then(function () {
                    callback && callback();
                });
            });
        });
    }])
    .controller('ModalAddFtpController', function ($scope, close, model, alertService) {

        $scope.data = model.data;

        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.create();

            promise.then(function () {
                alertService.add('success', 'Ftp wurde angelegt.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Ftp konnte nicht angelegt werden.');
            });
        };
    });
