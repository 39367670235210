angular.module('quattro.account.edit', [
    'ngRoute',
    'quattro.model',
    'quattro.account.form',
    'quattro.core.alert.service',
])
    .controller('EditAccountCtrl', function ($scope, $routeParams, alertService, $location, Account, $anchorScroll, $window) {

        var account = $routeParams.account;
        var section = $routeParams.section;

        $window.setTimeout(function () {
            $anchorScroll(section);
        }, 1);


        var model = new Account({
            account: account
        });
        model.get();

        $scope.data = model.data;


        $scope.cancel = function () {
            $location.path('/account=' + account);
        };

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Account ' + account + ' wurde gespeichert.');
                $location.path('/account=' + account);
            }, function (reason) {
                alertService.add('error', 'Account ' + account + ' konnte nicht vollständig gespeichert werden.');
            });
        };
    });
