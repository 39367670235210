angular.module('quattro.core.model.prototype', [
    'quattro.core.tools',
    'quattro.core.hpls_api_1',
    'quattro.core.alert.service',
    'quattro.core.user.service',
])
    .factory('Model', function ($log, $q, api, userService, alertService, resolvePath, $translate) {

        function noop() {
        }

        function identity(x) {
            return x;
        }


        // filter
        var filter = {
            UTCToLocale: function (x) {
                if (x === undefined) {
                    return undefined;
                }

                var date = new Date(x);
                var day = date.getDate();
                var month = date.getMonth() + 1;    // `getMonth` is zero-indexed
                var year = date.getFullYear();

                // prefix zero
                day = day < 10 ? '0' + day : day;
                month = month < 10 ? '0' + month : month;

                var formatted = [
                    year,
                    month,
                    day,
                ].join('-');

                return !isNaN(date.getTime()) ? formatted : '';
            },
            strToInt: function (x) {
                return +x;
            },
            strToNumber: function (x) {
                if (angular.isString(x))
                    return +x.replace(',', '.');
                return x;
            },
            strToBoolean: function (x) {
                return x === 'true';
            },
        };


        function isAuthorized(config, key) {

            var userLevel = userService.get('userLevel');

            // string: when 'all' allow all, otherwise reject
            if (angular.isString(config)) {
                return (config === 'all');
            }

            // array: list of authorized user levels
            // check against current user level
            if (angular.isArray(config)) {
                // is userLevel in config array
                if (config.indexOf(userLevel) !== -1) {
                    return true;
                } else {
                    return false;
                }
            }

            // object: attribute level definition
            // keys in `config.attributes` are model names, their values are list
            // of authorized user levels
            // `default` is list of authorized user levels for all keys not handled before
            var perAttribute = config.attributes && config.attributes[key];
            if (perAttribute) {
                if (perAttribute.indexOf(userLevel) !== -1) {
                    return true;
                } else {
                    return false;
                }
            }

            // fallback to default for given scenario
            var defaultAttribute = config.default;
            if (defaultAttribute.indexOf(userLevel) !== -1) {
                return true;
            }

            return false;
        }


        // each model should register its scenarios on `this.scenarios`. common
        // scenarios are `read`, `create`, `update`, `remove`. the scenario a model
        // is created for should be stored on `this.scenario`.
        //
        // a scenario is an array of request configuration object, f.e.:
        //
        // this.scenarios = {
        //    read: [config],
        //    create: [config],
        //    update: [config1, config2, config3],
        //    remove: [config],
        // }
        //
        // a configuration object represents a jsonrpc request and includes some
        // configuration how to handle parameters and return values, e.g.:
        //
        // {
        //   method: 'jsonrpc_method_name',
        //   params: {},
        //   results: {},
        // }
        //
        // method:
        // the json rpc request method.
        //
        // params:
        // the object properties list the names of all model attributes which are
        // used in this request. the value of these properties configure their
        // behavior towards the api, f.e. api may use different naming, or the model
        // value may be casted to int before including in request `params` object.
        //
        // f.e.:
        //
        // this.scenarios.read = {
        //   method: 'do_something',
        //   params: {
        //     'name': {
        //       required: true,
        //       api_name: 'userName'
        //     },
        //     'age': {
        //       filter: function (x) { return Number.parseInt(x); }
        //     }
        //   },
        // }
        //
        // will send the request:
        //
        // {
        //   method: 'do_something',
        //   params: {
        //     'api_name': this.data.name,
        //     'age': (function (x) { return Number.parseInt(x); })(this.data.age)
        //   }
        // }
        //
        // params config object attributes and their default values:
        //
        // {
        //   primary_key: false,
        //   required: false,               // (*)
        //   always_send: false,            // even if not required, not dirty, ...
        //   api_name: 'local_name',        // defaults to same as local name
        //   filter: identity,
        //   undefined_if_empty: false,
        //   validate_only: false,
        // }
        //
        // (*) our api supports a special 'required but empty string allowed'-case,
        // which currently isn't supported by our client. see VQ-297, VQ-505
        //
        // results:
        // how the result object is mapped to local model attributes, the idea is
        // similar to `params` above.
        //
        // result config objects support:
        //
        // {
        //   api_name: 'local_name',        // defaults to same as local name
        //   filter: identity,
        // }
        //
        // instead of giving full config objects there are shortcuts for `params`
        // and `results`:
        // `{}` or `null`: use default behaviour (actually any falsy value will do)
        // `true`: is required
        // `api_name`: map to given remote name


        // parses given params config.
        // returns normalized params config object.
        function normalizeParams(key, config, model) {

            // everything falsy will result in default settings
            config = config || {};

            // handle shortcuts

            if (config === true) {
                config = {required: true};
            }

            if (typeof config === 'string') {
                config = {api_name: config};
            }

            var required = config.required;
            if (config.required_if && config.required_if(model.data)) {
                required = true;
            }

            return {
                primary_key: !!config.primary_key,
                required: !!required,
                always_send: !!config.always_send,
                api_name: config.api_name || key,
                filter: config.filter || identity,
                undefined_if_empty: config.undefined_if_empty ? true : false,
                validate_only: config.validate_only ? true : false,
            };
        }


        // parses given results config.
        // return normalized results config object.
        function normalizeResults(key, config) {

            // everything falsy will result in default settings
            config = config || {};

            // handle shortcuts

            if (typeof config === 'string') {
                config = {api_name: config};
            }

            return {
                api_name: config.api_name || key,
                filter: config.filter || identity,
            };
        }


        function getScenario() {
            return this.scenario || 'update';
        }


        function decorateWithLog(command) {
            var onRejected = command.onRejected || noop;
            command.onRejected = function (error) {
                $log.error(command.method, error.code, error.message);
                onRejected(error);
            };
        }


        // hmm, dependency on view?
        function decorateWithAlertService(command) {
            var onRejected = command.onRejected || noop;
            command.onRejected = function (error) {
                // session expired handled elsewhere
                if (error.code !== 253) {
                    if (typeof error.data !== 'undefined' && command.method === 'account.set' && error.code === 398) {
                        if (typeof error.data.mails !== 'undefined') {
                            if (error.data.mails === 1) {
                                alertService.add('error', $translate('ACCOUNT.account_over_limit_email'));
                            } else {
                                alertService.add('error', $translate('ACCOUNT.account_over_limit_emails', {limit: error.data.mails}));
                            }
                        }
                        if (typeof error.data.forwards !== 'undefined') {
                            if (error.data.forwards === 1) {
                                alertService.add('error', $translate('ACCOUNT.account_over_limit_forward'));
                            } else {
                                alertService.add('error', $translate('ACCOUNT.account_over_limit_forwards', {limit: error.data.forwards}));
                            }
                        }
                        if (typeof error.data.contexts !== 'undefined') {
                            if (error.data.contexts === 1) {
                                alertService.add('error', $translate('ACCOUNT.account_over_limit_context'));
                            } else {
                                alertService.add('error', $translate('ACCOUNT.account_over_limit_contexts', {limit: error.data.contexts}));
                            }
                        }
                    } else if (Array.isArray(error.data) && error.data.length > 0 && command.method === 'mail.set' && error.code === 398) {
                         var data = error.data.filter(function (entry) {
                             return entry.hasOwnProperty('reason') && entry.reason === 'etherpadsLightNotAllowed';
                         });
                         if (data.length > 0) {
                             alertService.add('error', $translate('EMAIL.mail_set_etherpads_light_not_allowed'));
                         }
                    } else {
                        alertService.add('error', ['Error in:', command.method, error.code, error.message].join(' '));
                    }
                }
                onRejected(error);
            };
        }

        function isDirty(model, key) {
            var orig = model.data_orig || {};
            var data = model.data || {};
            return orig[key] !== data[key];
        }


        // gets a single request configuration object (see `this.scenarios` above).
        // returns a "command" object, which is a jsonrpc request object w/
        // `onFulfilled` and `onRejected` handlers.
        function createCommand(commandConfig) {
            var model = this;
            var action = model.action;
            var method = commandConfig.method;
            var paramsConfig = commandConfig.params || {};
            var resultsConfig = commandConfig.results || {};
            var params = {};
            var hasPayload = false;


            // omit this command on some conditions, f.e. for catchall mail
            // addresses which support less features than normal addresses.
            if (commandConfig.skip_if && commandConfig.skip_if(model.data)) {
                return;
            }

            angular.forEach(paramsConfig, function (config, key) {

                config = normalizeParams(key, config, model);

                var apiName = config.api_name;
                var filter = config.filter;
                var useValue = (config.primary_key || config.required || config.always_send || isDirty(model, key));
                var value = useValue ? filter(model.data[key], model.data, model) : undefined;

                if (config.validate_only) {
                    return;
                }

                if (config.undefined_if_empty && value === '') {
                    value = undefined;
                }

                if (!config.primary_key && !config.always_send && value === undefined) {
                    return;
                }


                // check authorization

                // always allow primary keys
                var primary_key = config.primary_key;
                var authorized = isAuthorized(model.authorization[action], key);

                if (primary_key || authorized) {
                    params[apiName] = value;
                    if (!config.primary_key) {
                        hasPayload = true;
                    }
                }
            });

            // `create` and `update` commands usually need payload besides their
            // primary keys. in this case we early exit if there's no payload.
            // `read` and `remove` usually work w/o payload.
            if (['create', 'update'].indexOf(action) !== -1 && !hasPayload) {
                return;
            }

            var onFulfilled = function (result) {
                angular.forEach(resultsConfig, function (config, key) {
                    config = normalizeResults(key, config);
                    var apiName = config.api_name;
                    var filter = config.filter;
                    var value = resolvePath.call(result, apiName);
                    model.resultCache[key] = filter(value, result);
                });
            };

            var onRejected = commandConfig.onRejected;

            return {
                method: method,
                params: params,
                onFulfilled: onFulfilled,
                onRejected: onRejected,
            };
        }

        // creates and sends commands, based on given command configs (see
        // `this.scenario`) and current state of this model (f.e. current state of
        // `this.data`).
        // callback handlers will be executed as soon as each ajax response arrives.
        // received data will be exposed on `model.data` once _all_ commands
        // succeeded. if any one failed all results will be discarded.
        // returns promise which resolves w/ this model on success or rejects.
        function sendCommands(commandConfigs) {
            var model = this;
            model.resultCache = {};

            // some commands have requirements regarding their order, VQ-383
            // delay some commands to be executed in a second wave after the others
            var delayedConfigs = [];
            commandConfigs = commandConfigs.filter(function (config) {
                var delay = config.delay_if && config.delay_if(model.data);
                if (delay) {
                    delayedConfigs.push(config);
                }
                return !delay;
            });

            var commands = commandConfigs
                .map(createCommand.bind(model))
                .filter(function (command) {
                    return command; // check if command is valid (aka: not undefined)
                });

            commands.forEach(decorateWithLog);
            commands.forEach(decorateWithAlertService);


            return api.many(commands)
                .then(function () {
                    // first wave done, send delayed commands

                    var delayed = delayedConfigs
                        .map(createCommand.bind(model))
                        .filter(function (command) {
                            return command; // check if command is valid (aka: not undefined)
                        });

                    delayed.forEach(decorateWithLog);
                    delayed.forEach(decorateWithAlertService);

                    return api.many(delayed);
                })
                .then(function () {

                    // nested arrays are considered one atomic item. when the new
                    // array has less items we want that update.
                    angular.forEach(model.resultCache, function (value, key) {
                        if (angular.isArray(value)) {
                            if (model.data_orig) {
                                // make sure to create a copy (`slice`), ref VQ-569
                                model.data_orig[key] = value.slice();
                            }
                            model.data[key] = value;
                        }
                    });

                    model.data_orig = angular.merge(model.data_orig || {}, model.resultCache);
                    model.data = angular.merge(model.data, model.resultCache);
                    return $q.when(model);
                })
                .finally(function () {
                    delete model.resultCache;
                });
        }


        // validates given key-value for this model. depends on properly configured
        // `this.scenarios`.
        // @returns promise, if invalid rejects w/ api error object (`code`, `message`).
        function validate(key, value) {

            // early exit if not yet loaded in 'update' scenario.
            if (this.getScenario() === 'update' && !this.loaded) {
                return $q.when();
            }

            var model = this;
            var validation = $q.defer();

            // gather commands for this model's scenario
            var scenarios = model.scenarios || {};
            var scenario = model.getScenario();
            var commands = scenarios[scenario] || [];

            // filter for the ones including `key`
            commands = commands.filter(function (command) {
                var params = command.params;
                return params[key] !== undefined;
            });

            if (commands.length === 0) {
                // `key` unknown, return anything, resolve for now..
                return $q.when();
            }

            // api doesn't give us "required" as validation error, have to check
            // for "empty" values manually.
            // empty values may be
            // '': form field w/ nothing in it
            // undefined: before first `get` has finished
            // null: set by `ui-date`
            if (value === undefined || value === null || value === '') {

                // loop through commands, if any has this key as required reject
                // validation
                commands.forEach(function (command) {
                    var config = command.params[key];
                    config = normalizeParams(key, config, model);
                    if (config.required) {
                        // required, will always fail if empty
                        validation.reject({
                            code: 7,
                            message: 'required parameter missing.',
                        });
                    }
                });

                // if not required empty strings default to `valid`.
                // `resolve` is noop if promise is no longer `pending`.
                validation.resolve();

                // early exit
                return validation.promise;
            }

            // check authorization
            // validate against api only if we have write authority for this `key`
            // in this scenario

            // talk to api
            var validated = commands.map(function (command) {
                var method = command.method;
                var config = command.params[key];
                config = normalizeParams(key, config, model);

                var apiName = config.api_name;

                var params = {};
                params[apiName] = config.filter(value, model.data);
                params.validate_only = true;

                return api.single(method, params);
            });

            $q.all(validated).then(function () {
                validation.resolve();
            }, function (errors) {
                // shouldn't `errors` be an array?
                validation.reject(errors);
            });
            return validation.promise;
        }


        function Model() {
        }

        Model.prototype = {
            filter: filter,
            getScenario: getScenario,
            isDirty: function (attribute) {
                return isDirty(this, attribute);
            },
            get: function get() {
                var model = this;
                model.action = 'read';
                var commandConfigs = this.scenarios && this.scenarios.read || [];
                return sendCommands.bind(this)(commandConfigs)
                    .then(function (model) {
                        model.loaded = true;
                        model.action = undefined;
                        return model;
                    });
            },
            save: function save() {
                var scenario = this.getScenario();
                switch (scenario) {
                    case 'create':
                        // send create specific commands
                        return this.create();
                    case 'update':
                        // send update specific commands
                        // don't update if not yet loaded
                        if (!this.loaded) {
                            return $q.reject();
                        }
                        return this.update();
                }
                return $q.reject();
            },
            create: function create() {
                var model = this;
                model.action = 'create';
                var commandConfigs = this.scenarios && this.scenarios.create || [];
                //var results =
                return sendCommands.bind(this)(commandConfigs)
                    .finally(function () {
                        model.action = undefined;
                    });
            },
            update: function update() {
                var model = this;
                // early exit if not yet loaded in 'update' scenario.
                if (!this.loaded) {
                    return $q.reject();
                }
                model.action = 'update';
                var commandConfigs = this.scenarios && this.scenarios.update || [];

                if (model.onRejected !== undefined) {
                    commandConfigs.map(function(command) {
                        command.onRejected = model.onRejected;
                        return command;
                    })
                }

                return sendCommands.call(this, commandConfigs)
                    .finally(function () {
                        model.action = undefined;
                    });
            },
            remove: function remove() {
                var model = this;
                model.action = 'remove';
                var commandConfigs = this.scenarios && this.scenarios.remove || [];
                return sendCommands.bind(this)(commandConfigs)
                    .finally(function () {
                        model.action = undefined;
                    });
            },
            validate: validate,
        };
        return Model;
    });
