angular
    .module('quattro.core.viewhelper', [])
    .factory('viewHelper', function () {

        var $ = angular.element;

        // searches dom for invalid form fields. scrolls to first, if any.
        // @returns `true` if form is valid, false otherwise.
        function formIsValid() {
            // FIXME: find better way to gather validity of form without stumbling over search form validity.
            // mayhap en/disable submit button depending on forma name on scope?
            // does that work w/ our add forward before save hack?
            var invalid = $('input.ng-invalid-api, input.ng-invalid-quattro-password-form');

            if (invalid.length === 0) {
                // early exit
                return true;
            }

            var id = invalid.first().attr('id');
            if (id) {
                var offset = -2 * $('header[id=header]').height();
                $.smoothScroll({scrollTarget: '#' + id, offset: offset});
            }
            return false;
        }

        return {
            formIsValid: formIsValid
        };
    });
