angular.module('quattro.email.edit', [
    'ngRoute',
    'quattro.model',
    'quattro.email.form',
    'quattro.core.alert.service',
])
    .controller('EditEmailCtrl', function ($scope, $routeParams, alertService, $location, Mail) {

        var domain = $routeParams.domain;
        var mailName = $routeParams.email || '';
        var mail = mailName + '@' + domain;

        var model = new Mail({
            domain: domain,
            mail: mail
        });
        model.get();

        $scope.data = model.data;


        $scope.cancel = function () {
            $location.path('/domain=' + domain + '/email=' + mailName);
        };

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Mail ' + mail + ' wurde gespeichert.');
                $location.path('/domain=' + domain + '/email=' + mailName);
            }, function (reason) {
                alertService.add('error', 'Mail ' + mail + ' konnte nicht vollständig gespeichert werden.');
            });
        };
    });
