angular
    .module('quattro.core.modal-confirm', [
        'quattro.core.myModal',
    ])
    .directive('quattroConfirm', function () {
        return {
            restrict: 'E',
            scope: {
                confirm: '&',
                cancel: '&',
                confirmLabel: '@',
                cancelLabel: '@',
            },
            transclude: true,
            templateUrl: 'core/modal-confirm/quattro-confirm-directive.html',
        };
    });
