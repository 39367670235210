angular
    .module('quattro.jpb.search', [
        'quattro.core.hpls_api_1',
        'quattro.core.alert.service',
    ])
    .factory('searchService', function ($log, api, alertService) {

        var scope = {
            query: '',
            accounts: [],
            mailinglists: [],
            domains: [],
            emails: [],
            ftps: [],
            hasResults: false,
            loading: false,
        };

        function hasResults() {
            var accounts = scope.accounts;
            var mailinglists = scope.mailinglists;
            var domains = scope.domains;
            var emails = scope.emails;
            var ftps = scope.ftps;

            if (accounts && accounts.length || mailinglists && mailinglists.length || domains && domains.length || emails && emails.length || ftps && ftps.length) {
                return true;
            }
            return false;
        }

        // wrap string represenations for account, domain, ... (as we get them
        // from api) in objects, following model naming scheme.

        function wrapAccount(str) {
            return {
                account: str,
            };
        }

        function wrapMailinglist(str) {
            return {
                mailinglist: str,
            };
        }

        function wrapDomain(str) {
            return {
                domain: str,
            };
        }

        function wrapMail(str) {
            return {
                mail: str,
            };
        }

        function wrapFtp(str) {
            return {
                ftp: str,
            };
        }


        function sendRequest(method, params) {

            scope.accounts = [];
            scope.mailinglists = [];
            scope.domains = [];
            scope.emails = [];
            scope.ftps = [];
            scope.hasResults = hasResults();
            scope.loading = true;

            api
                .single(method, params)
                .then(function (result) {

                    var accounts = result.accounts || [];
                    var mailinglists = result.mailinglists || [];
                    var domains = result.domains || [];
                    var mails = result.mails || [];
                    var ftps = result.ftps || [];

                    scope.accounts = accounts.map(wrapAccount);
                    scope.mailinglists = mailinglists.map(wrapMailinglist);
                    scope.domains = domains.map(wrapDomain);
                    scope.emails = mails.map(wrapMail);
                    scope.ftps = ftps.map(wrapFtp);

                    scope.hasResults = hasResults();

                }, function (error) {
                    $log.error('call failed! code: ' + error.code + ' - message: ' + error.message);
                    alertService.add('error', ['Error in:', method, error.code, error.message].join(' '));
                })
                .finally(function () {
                    scope.loading = false;
                });
        }

        function adminSearch(query) {
            var method = 'q.admin.search';
            var params = {'query': query};
            sendRequest(method, params);
        }

        function accountSearch(account, query) {
            var method = 'q.account.search';
            var params = {'account': account, 'query': query};
            sendRequest(method, params);
        }

        function mailinglistSearch(mailinglist, query) {
            var method = 'q.mailinglist.search';
            var params = {'mailinglist': mailinglist, 'query': query};
            sendRequest(method, params);
        }

        function domainSearch(domain, query) {
            var method = 'q.domain.search';
            var params = {'domain': domain, 'query': query};
            sendRequest(method, params);
        }

        return {
            adminSearch: adminSearch,
            accountSearch: accountSearch,
            mailinglistSearch: mailinglistSearch,
            domainSearch: domainSearch,
            scope: scope,
        };
    });
