angular
    .module('quattro.email.widget_emaillist', [
        'quattro.core.modal-confirm',
        'quattro.email.modal_add',
        'quattro.email.modal_edit',
    ])
    .directive('emaillist', function () {
        return {
            templateUrl: 'jpb/email/widget_emaillist.html',
            controller: function ($scope, $routeParams, Mail, myModalService, $q, $filter) {
                var domain = $routeParams.domain;

                // manages api calls to delete list pf ftps, returns promise
                function deleteEmailList(mails) {
                    var promisePool = mails.map(function (mail) {
                        var domain = $filter('email_domain_part')(mail);
                        return new Mail({
                            domain: domain,
                            mail: mail,
                        }).remove();
                    });
                    return $q.all(promisePool);
                }


                $scope.modalAddEmail = function () {

                    var maillist = $scope.maillist;

                    var has_catchall = maillist.reduce(function (prev, current) {
                        return prev || current.mail && current.mail.startsWith('@');
                    }, false);

                    $scope.$emit('vq.mail.add', {
                        domain: domain,
                        allow_catchall: !has_catchall,
                        allow_forwards: true,
                    }, function () {
                        $scope.loadEmailList();
                    });
                };

                $scope.quickEditEmail = function (mail) {

                    // custom event, will trigger modal edit
                    // callback will be called after modal has closed
                    $scope.$emit('vq.mail.edit', mail, function () {
                        $scope.loadEmailList();
                    });
                };

                $scope.loadEmailList = function () {
                    var promise = Mail.query(domain);
                    promise.then(function (result) {
                        $scope.maillist = result;
                    });
                };

                $scope.loadEmailList();

                $scope.orderByExpression = 'mail';
                $scope.orderByReverse = false;

                $scope.orderBy = function (predicate) {
                    $scope.orderByReverse = ($scope.orderByExpression === predicate) ? !$scope.orderByReverse : false;
                    $scope.orderByExpression = predicate;
                };
                $scope.orderedClass = function (predicate) {
                    if ($scope.orderByExpression === predicate) {
                        return $scope.orderByReverse ? 'sort-desc' : 'sort-asc';
                    }
                    return '';
                };

                $scope.maillistSelected = function () {
                    var list = $scope.maillist;
                    var filtered = list.filter(function (item) {
                        return item.$checked;
                    });
                    return filtered.length ? filtered : list;
                };

                $scope.deleteSelectedEmail = function () {

                    var list = $scope.maillistSelected().map(function (value) {
                        return value.mail;
                    });

                    myModalService.showModal({
                        templateUrl: 'jpb/email/modal_confirm_delete.html',
                        inputs: {
                            list: list,
                            reload: $scope.loadEmailList,
                        },
                        controller: ['$scope', 'close', 'list', 'reload', function ($scope, close, list, reload) {
                            $scope.list = list;
                            $scope.cancel = function () {
                                close();
                            };
                            $scope.confirm = function () {
                                close();
                                deleteEmailList(list)
                                    .finally(function () {
                                        reload();
                                        angular.element('.itemlist').removeClass('show-contextual');
                                    });
                            };
                        }],
                    });
                };
            }
        };
    });
