angular.module('quattro.ftp.view', [
    'quattro.ftp.widget_info',
    'quattro.jpb.model.ftp',
    'ngRoute',
])
    .controller('FtpCtrl', function ($scope, $routeParams, $location, $log, Ftp) {

        var domain = $routeParams.domain;
        var ftp = $routeParams.ftp;

        var model = new Ftp({
            domain: domain,
            ftp: ftp
        });
        model.get();

        $scope.data = model.data;

        $scope.edit = function () {
            $location.path('/domain=' + domain + '/ftp=' + ftp + '/edit');
        };

    });
