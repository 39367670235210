angular
    .module('quattro.ftp.edit', [
        'ngRoute',
        'quattro.model',
        'quattro.ftp.form',
        'quattro.core.alert.service',
    ])
    .controller('EditFtpCtrl', function ($scope, alertService, $location, $routeParams, Ftp) {

        var domain = $routeParams.domain;
        var ftp = $routeParams.ftp;

        var model = new Ftp({
            domain: domain,
            ftp: ftp
        });
        model.get();

        $scope.data = model.data;

        $scope.cancel = function () {
            $location.path('/domain=' + domain + '/ftp=' + ftp);
        };

        $scope.save = function () {

            var promise = model.update();
            promise.then(function () {
                alertService.add('success', 'Ftp ' + ftp + ' wurde editiert!');
                $location.path('/domain=' + domain + '/ftp=' + ftp);
            }, function (reason) {
                alertService.add('error', 'Ftp ' + ftp + ' konnte nicht vollständig gespeichert werden.');
            });
        };
    });
