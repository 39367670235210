angular.module('quattro.jpb.login.password-reset', [
    'quattro.core.hpls_api_1',
    'quattro.core.alert.service',
    'quattro.core.form-helper.checkval'
])
    .controller('JpbPasswordResetController', function (api, alertService) {

        this.done = false

        this.reset = function () {

            api
                .single('password.reset', {mail: this.mail})
                .then(handleResponse.bind(this), function (error) {
                    alertService.add('error', api.translate(error));
                })
        };

        function handleResponse() {
            this.done = true
        }
    });
