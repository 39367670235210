// wraps third party modal service, allowing central open / close callbacks

angular
    .module('quattro.core.myModal', [
        'angularModalService'
    ])
    .factory('myModalService', function (ModalService) {

        var body = angular.element('body');
        var modalClose; // function, which closes current modal, if any


        // handles click events, closes current modal on click on pseudo element
        // (`::after`) on `main#main`
        function modalClickHandler(event) {
            var target = angular.element(event.target);
            if (target.is('main#main')) {
                modalClose && modalClose();
            }
        }

        // handles key events, closes current modal on escape
        function modalKeyHandler(event) {
            if (event.which === 27) {
                modalClose && modalClose();
            }
        }

        // called when a modal opens
        function modalOpened() {
            body.on('click', '#main', modalClickHandler);
            body.on('keyup', modalKeyHandler);
            body.addClass('modal-open');
        }

        // called when modal closes
        function modalClosed() {
            body.off('click', '#main', modalClickHandler);
            body.off('keyup', modalKeyHandler);
            body.removeClass('modal-open');
        }

        // wraps upstream's `showModal`, injects our open/close callbacks, returns
        // upstream's promise
        function modalOpen() {
            var promise = ModalService.showModal.apply(ModalService, arguments);
            promise.then(function (modal) {
                modalClose = modal.scope.cancel || (modal.scope.vm && modal.scope.vm.cancel);
                modalOpened();
                modal.close.then(function () {
                    modalClose = undefined;
                    modalClosed();
                });
            });
            return promise;
        }

        return {
            showModal: modalOpen,
        };
    });
