angular
    .module('quattro.domain.form', [
        'quattro.core.filters',
        'quattro.core.password-form',
        'quattro.core.viewhelper',
        'quattro.directives',
    ])
    .directive('quattroDomainForm', [function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                isCreate: '=?',
                cancel: '=',
                // will be called after successfull validation
                save: '=',
            },
            controller: 'domainFormController',
            controllerAs: 'vm',
            templateUrl: 'jpb/domain/widget_form.html',
        };
    }])
    .controller('domainFormController', [
        '$scope',
        '$filter',
        'userService',
        'viewHelper',
        'jpb_domain_states',
        'domain_types',
        'jpb_user_selectable_domain_types',
        'letsencrypt_types',
        'jpb_blacklisted_domain_types',
        function ($scope, $filter, userService, viewHelper, jpb_domain_states, domain_types, jpb_user_selectable_domain_types, letsencrypt_types, jpb_blacklisted_domain_types) {

            this.types = domain_types;
            this.states = jpb_domain_states;

            $scope.types_selectable = undefined;
            $scope.type_help_text = undefined;

            $scope.$watch(function () {
                return $scope.data.domaintype_change_blacklisted;
            }, function (newValue, oldValue, scope) {
                // Check if domain is blacklisted for changing its type
                if (newValue === true) {
                    scope.types_selectable = null;
                    scope.type_help_text = 'DOMAIN.type_help_texts.domaintype_change_blacklisted';
                }
            });

            $scope.$watch(function () {
                return $scope.data.type;
            }, function (newValue, oldValue, scope) {
                // Generate list only at the beginning, not every time the selection changes
                if (newValue && $scope.types_selectable === undefined) {
                    // Domain types that the user (not the admin) can choose
                    if (jpb_blacklisted_domain_types.indexOf(newValue) !== -1) {
                        scope.types_selectable = null;
                        scope.type_help_text = 'DOMAIN.type_help_texts.domaintype_change_blacklisted';
                    }
                    if (jpb_user_selectable_domain_types.count === 1 && jpb_user_selectable_domain_types[0] === newValue) {
                        // Hide the field if there is only one domain kind that could be switched to and the user already has this one
                        scope.types_selectable = null;
                    } else {
                        if (jpb_user_selectable_domain_types.indexOf(newValue) !== -1) {
                            scope.types_selectable = jpb_user_selectable_domain_types;
                            scope.type_help_text = 'DOMAIN.type_help_texts.JPBERLIN-PHP_supported';
                        } else {
                            scope.types_selectable = null;
                            scope.type_help_text = 'DOMAIN.type_help_texts.domaintype_change_blacklisted';
                        }
                    }
                }
            });

            $scope.$watch(function () {
                return $scope.data.type;
            }, function (newValue, oldValue, scope) {
                scope.letsencrypt_available = letsencrypt_types.indexOf(newValue) !== -1;
            });

            this.clearFibunextdate = function () {
                $scope.data.fibunextdate = '';
            };


            $scope.validate_dkim_dns_record_name = function () {
                $scope.domainForm.dkim_dns_record_name.$validate();
            };
            $scope.validate_dkim_private_key = function () {
                $scope.domainForm.dkim_private_key.$validate();
            };

            $scope.$watchGroup(['data.memo_otrs'], function (newValues, oldValues, scope) {
                scope.data.admin_memo = '';
                if (newValues[0])
                    scope.data.admin_memo += newValues[0];
            });

            this.user = userService.scope;

            this.cancel = $scope.cancel;

            this.save = function () {

                // ngForm.$validate();

                if (!viewHelper.formIsValid()) {
                    return;
                }

                $scope.save();
            };
        }
    ]);
