angular
    .module('quattro.email', [
        'quattro.email.view',
        'quattro.email.widget_info',
        'quattro.email.widget_forwardlist',
        'quattro.email.form',
        'quattro.email.edit',
        'quattro.email.modal_add',
        'quattro.email.modal_edit',
        'quattro.email.vacation',
    ]);
