angular
    .module('quattro.mailinglist.form', [
        'quattro.core.filters',
        'quattro.core.password-form',
        'quattro.core.viewhelper',
        'quattro.directives',
    ])
    .directive('quattroMailinglistForm', [function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                isCreate: '=?',
                cancel: '=',
                save: '=',
            },
            controller: 'mailinglistFormController',
            controllerAs: 'vm',
            templateUrl: 'jpb/mailinglist/widget_form.html',
        };
    }])
    .controller('mailinglistFormController', [
        '$scope',
        '$filter',
        'userService',
        'viewHelper',
        'Domain',
        function ($scope, $filter, userService, viewHelper, Domain) {

            this.user = userService.scope;

            this.cancel = $scope.cancel;

            this.save = function () {
                if (viewHelper.formIsValid()) {
                    $scope.save();
                }
            };

            $scope.$watch('data.adminmail', function (newValue, oldValue) {
                if(newValue === "")
                    delete $scope.data.adminmail;
            });

            this.loadDomainList = function () {
                var promise = Domain.query($scope.data.account);
                promise.then(function (result) {
                    $scope.availableDomains = result;
                    $scope.availableDomains.unshift({'domain': 'listi.jpberlin.de'});
                });
            };

            this.loadDomainList();
        }
    ]);
