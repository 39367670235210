angular
    .module('quattro.jpb.contact-forms.domain-change', [
        'quattro.core.support',
        'quattro.core.modal-confirm',
        'quattro.jpb.model.domain',
    ])
    .run(function ($rootScope, myModalService) {

        $rootScope.$on('vq.contact.domain-change', function (event, account) {

            myModalService.showModal({
                templateUrl: 'jpb/contact-forms/modal-contact-domain-change.html',
                controller: 'ContactFormDomainChangeController',
                controllerAs: 'vm',
                inputs: {
                    account: account,
                }
            });

        });
    })
    .controller('ContactFormDomainChangeController', function ($interpolate, $templateCache, close, $scope, account, SupportService, Domain, alertService, $translate) {

        $scope.domains = [];
        Domain.query(account).then(function (result) {
            $scope.domains = result;
        });

        $scope.classes = {};
        $scope.data = {
            account: account,
        };

        this.cancel = close;

        this.submit = function () {
            var validationError = false;
            $scope.classes = {};

            function validateField(action, field) {
                if ((action === true || $scope.data[action]) && !$scope.data[field]) {
                    validationError = true;
                    $scope.classes[field] = 'input-mandatory-warning';
                }
            }

            validateField(true, 'domain');

            var actionChosen = $scope.data.actionChangeDNS || $scope.data.actionDeleteDNS || $scope.data.actionRedirect ||
                $scope.data.actionDocRoot || $scope.data.actionServerSettings || $scope.data.actionWhois || $scope.data.actionNameserver;

            if (!actionChosen) {
                validationError = true;
                $scope.classes.actionCheckboxes = 'input-mandatory-warning';
            }

            validateField('actionChangeDNS', 'changeDNS');
            validateField('actionDeleteDNS', 'deleteDNS');
            validateField('actionRedirect', 'redirectType');
            validateField('actionRedirect', 'redirect');
            validateField('actionDocRoot', 'docroot');
            validateField('actionServerSettings', 'serversettings');

            if ($scope.data.actionWhois) {
                var whoisFields = [
                    'Company',
                    'Firstname',
                    'Lastname',
                    'Street',
                    'Zipcode',
                    'City',
                    'State',
                    'Country',
                    'Phone',
                    'Fax',
                    'Email',
                ];

                var whoisHasEntries = false;
                whoisFields.forEach(function (field) {
                    if ($scope.data['whoisChange' + field]) {
                        whoisHasEntries = true;
                    }
                    validateField('whoisChange' + field, 'whois' + field);
                });

                if (!whoisHasEntries) {
                    validationError = true;
                    $scope.classes.whoisText = 'attention-message';
                }
            }

            validateField('actionNameserver', 'nameserver1');
            validateField('actionNameserver', 'nameserver2');

            if (validationError) {
                alertService.add('error', $translate('CONTACT.mandatory_error'));
            } else {
                var templateSupport = $templateCache.get('jpb/contact-forms/mail-templates/domain-change.support.txt');
                var templateCustomer = $templateCache.get('jpb/contact-forms/mail-templates/domain-change.customer.txt');
                var templateSubject = $templateCache.get('jpb/contact-forms/mail-templates/domain-change.subject.txt');

                var textSupport = $interpolate(templateSupport)($scope.data);
                var textCustomer = $interpolate(templateCustomer)($scope.data);
                var subject = $interpolate(templateSubject)($scope.data).trim();

                SupportService.create(subject, textSupport, subject, textCustomer).then(function () {
                    close();
                    alertService.add('info', $translate('CONTACT.inquiry_success'));
                });
            }
        };

    });
