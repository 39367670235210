angular
    .module('quattro.account.form', [
        'quattro.core.viewhelper',
        'quattro.core.country-picker',
        'quattro.directives',
        'quattro.email.vacation',
        'quattro.forward.widget_edit',
        'quattro.core.password-form',
    ])
    .directive('quattroAccountForm', [function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                isCreate: '=?',
                cancel: '=',
                // will be called after successfull validation
                save: '=',
            },
            controller: 'accountFormController',
            controllerAs: 'vm',
            templateUrl: 'jpb/account/widget_form.html',
        };
    }])
    .controller('accountFormController', [
        '$scope',
        'userService',
        'viewHelper',
        'jpb_states',
        'account_anrede',
        'invoice_types',
        'payment_types',
        function ($scope, userService, viewHelper, jpb_states, account_anrede, invoice_types, payment_types) {


            $scope.states = jpb_states;
            $scope.anreden = account_anrede;
            $scope.rechnungsarten = invoice_types;
            $scope.zahlungsarten = payment_types;

            $scope.forward_widget = {};


            $scope.$watch('data.payment_type', function (newValue, oldValue, scope) {
                scope.switchToDta = newValue === 'dta' && scope.data.$isDirty('payment_type');
            });


            $scope.validateForward = function (value) {
                return $scope.data.$validate('mail_forwards', [value]);
            };

            $scope.validateInboxsave = function (value) {
                return $scope.data.$validate('mail_inboxsave', value);
            };


            this.user = userService.scope;

            this.cancel = $scope.cancel;

            this.save = function () {

                $scope.forward_widget.flush();

                // ngForm.$validate();

                if (!viewHelper.formIsValid()) {
                    return;
                }

                $scope.save();
            };
        }]);
