angular.module('quattro.core.filters', [])

    .filter("email_local_part", function () {
        return function (input) {
            return input.split('@')[0];
        };
    })
    .filter("email_domain_part", function () {
        return function (input) {
            return input.split('@')[1];
        };
    })

    .filter("ftp_local_part", function () {
        return function (input) {
            return input.split('_')[1];
        };
    })
    .filter("ftp_domain_part", function () {
        return function (input) {
            return input.split('_')[0];
        };
    })
    .filter("punycode_toAscii", function () {
        return punycode.toASCII;
    })
    .filter("punycode_toUnicode", function () {
        return punycode.toUnicode;
    })
    .filter("is_idn_domain", function () {
        // input: a domain name
        // returns `true` if domain is idn domain, `false` otherwise
        return function (input) {
            return punycode.toASCII(input) !== punycode.toUnicode(input);
        };
    })
    .filter('prefix', function () {
        return function (input, prefix) {
            return '' + prefix + input;
        };
    })
    .filter('capitalize', function () {
        return function (input) {
            return (angular.isString(input) && input.length > 0) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : input;
        };
    })
    .filter('filterNullTariff', function () {
        return function (tariffs) {
            for (var tariff in tariffs) {
                if (tariffs[tariff] === null) {
                    delete tariffs[tariff];
                }
            }
            return tariffs;
        };
    })
    .filter('notNullTariffs', function () {
        return function (tariffs) {
            var numKeys = 0;
            for (var tariff in tariffs) {
                if (tariffs[tariff] != null) {
                    numKeys++;
                }
            }
            return numKeys;
        };
    });
