angular.module('quattro.account.view', [
    'ngRoute',
    'quattro.account.widget_info',
    'quattro.account.widget_invoices',
    'quattro.domain.widget_domainlist',
    'quattro.mailinglist.widget_mailinglistlist',
])
    .controller('AccountCtrl', function ($scope, $routeParams, $location, myModalService, api_url, Account) {

        var account = $routeParams.account;

        $scope.userName = $routeParams.account;

        // needed for invoice download url
        $scope.api_url = api_url;

        var model = new Account({
            account: account
        });

        // av contract modal
        $scope.modalAvContract = function (update) {
            //alert();
            myModalService.showModal({
                templateUrl: 'jpb/account/modal-avcontract.html',
                inputs: {
                    model: model,
                },
                controller: function ($scope, close, model) {
                    model.data.av_update = update;
                    if (model.data.av_contract_info_version !== undefined) {
                        model.data.av_contract_info_version_parseable = model.data.av_contract_info_version.replace(".", "_");
                        model.data.av_contract_old_version = model.data.av_contract_info_version;
                        if (model.data.av_contract_info_version === "V3.0" || update === 1) {
                            model.data.av_contract_info_version = "V3.0";
                            model.data.av_contract_info_version_parseable = 'V3_0';
                        } else if (model.data.av_contract_info_version === "V2.0") {
                            model.data.av_contract_info_version_parseable = 'V2_0';
                        } else if (model.data.av_contract_info_version === "V1.1"|| model.data.av_contract_info_version === "V1") {
                            model.data.av_contract_info_version_parseable = 'V1_1';
                        }
                    } else {
                        model.data.av_contract_info_version = "V3.0";
                        model.data.av_contract_info_version_parseable = 'V3_0';
                    }

                    $scope.data = model.data;
                    $scope.namevalid = false;
                    $scope.namevalidated = function () {
                        $scope.namevalid = $scope.data.av_contract_new_accept_name.length > 2 && $scope.data.av_contract_new_accept_name.length < 99;
                    };

                    $scope.printavcontract = function () {
                        var printContents = '';
                        printContents = document.getElementById('av-contract-print-area').innerHTML;
                        var popupWin = window.open('', '_blank', '');
                        popupWin.document.write('<html lang="de"><head><title></title></head><body><div id="contract-print-content">' + printContents + '</div></body></html>');
                        popupWin.document.close();
                        popupWin.focus();
                        popupWin.print();
                        popupWin.close();
                    };

                    var date = new Date();
                    $scope.datetoday = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

                    $scope.cancelconfirm = function () {
                        $scope.data.av_contract_new_accept_name = ''
                        model.get();
                        model.data.av_contract_info_version = $scope.data.av_contract_old_version;
                        close();

                    };
                    $scope.confirm = function () {
                        $scope.data.av_contract_accept_name = $scope.data.av_contract_new_accept_name
                        model.data.av_contract_info_version = $scope.data.av_contract_info_version;
                        model.update();
                        model.data.av_contract_signed = 'true';
                        model.data.av_contract_info_date = $scope.datetoday;
                        close();
                    };
                    $scope.closewindow = function () {
                        $scope.data.av_contract_new_accept_name = ''
                        model.data.av_contract_info_version = $scope.data.av_contract_old_version;
                        close();
                    };
                },
            });

        };

        model.get();

        $scope.data = model.data;

        $scope.reload = function () {
            model.get();
        };

        $scope.edit = function (section) {
            $location.path('/account=' + account + '/edit' + (section ? '/' + section : ''));
        };

        $scope.search = {};
    });
