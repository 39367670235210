angular
    .module('quattro.core.ng-indeterminate', [])
    .directive('ngIndeterminate', [function () {
        return {
            restrict: 'A',
            link: function (scope, element, attributes) {
                scope.$watch(attributes['ngIndeterminate'], function (value) {
                    element.indeterminate = !!true;
                });
            },
        };
    }]);
