// single source of truth for logged in / out state.

angular
    .module('quattro.core.user.service', [
        'quattro.core.alert.service',
        'quattro.core.user.sessionstorage',
    ])
    .factory('userService', function ($location, $log, quattroSessionStorage, alertService) {

        var hpls_auth_expired = false;
        var scope = {};
        exposeOnScope();

        function loggedIn() {
            var hplsAuth = quattroSessionStorage.get('hpls-auth');
            var userName = quattroSessionStorage.get('userName');
            var userLevel = quattroSessionStorage.get('userLevel');
            if (hplsAuth && userName && userLevel) {
                return true;
            } else {
                return false;
            }
        }

        function exposeOnScope() {
            scope.loggedIn = loggedIn();
            scope.isAdmin = quattroSessionStorage.get('userLevel') === 'admin';
            scope.isAccount = quattroSessionStorage.get('userLevel') === 'account';
            scope.isMailinglist = quattroSessionStorage.get('userLevel') === 'mailinglist';
            scope.isDomain = quattroSessionStorage.get('userLevel') === 'domain';
            scope.isEmail = quattroSessionStorage.get('userLevel') === 'mail';
            scope.isFtp = quattroSessionStorage.get('userLevel') === 'ftp';
        }

        function login(hplsAuth, userName, userLevel) {

            if (userName && userName.toLowerCase) userName = userName.toLowerCase();

            hpls_auth_expired = false;
            quattroSessionStorage.set('hpls-auth', hplsAuth);
            quattroSessionStorage.set('userName', userName);
            quattroSessionStorage.set('userLevel', userLevel);

            $log.info('logged in, user level: ' + userLevel);

            exposeOnScope();
        }

        function logout() {
            quattroSessionStorage.unset('hpls-auth');
            quattroSessionStorage.unset('userName');
            quattroSessionStorage.unset('userLevel');

            exposeOnScope();
        }

        function sessionExpired() {
            // show alert only once
            if (!hpls_auth_expired) {
                $log.info('clear expired session.');
                logout();
                // do not send user to login if he wants to change password
                if (!$location.$$path.startsWith('/password/set/')) {
                    $location.path('/login');
                }
            }
            hpls_auth_expired = true;
        }

        return {
            loggedIn: loggedIn,
            scope: scope,
            login: login,
            logout: logout,
            sessionExpired: sessionExpired,
            get: function (key) {
                if (loggedIn()) {
                    return quattroSessionStorage.get(key);
                }
            },
        };

    });
