angular
    .module('quattro.jpb.contact-forms.ap-change', [
        'quattro.core.support',
        'quattro.core.country-picker',
        'quattro.core.modal-confirm',
        'quattro.jpb.model.account',
    ])
    .run(function ($rootScope, myModalService) {

        $rootScope.$on('vq.contact.ap-change', function (event, account) {

            myModalService.showModal({
                templateUrl: 'jpb/contact-forms/modal-contact-ap-change.html',
                controller: 'ContactFormApChangeController',
                controllerAs: 'vm',
                inputs: {
                    account: account,
                }
            });

        });
    })
    .controller('ContactFormApChangeController', function ($interpolate, $templateCache, close, $scope, account, Account, SupportService, alertService, $translate) {

        $scope.data = {
            account: account,
        };

        new Account({account: account}).get().then(function (model) {
            $scope.data.actual_accountdata = model.data;
            $scope.data.new_country = model.data.address_land;
            $scope.data.actual_countryname = $translate.instant('COUNTRY.' + model.data.address_land);
        });

        this.cancel = close;

        this.submit = function () {
            if (!($scope.data.change_firstname || $scope.data.change_lastname || $scope.data.change_organisation || $scope.data.change_street || $scope.data.change_postcode || $scope.data.change_city || $scope.data.change_country)) {
                alertService.add('error', $translate('CONTACT.mandatory_error'));
            } else {
                if ($scope.data.change_country) {
                    $scope.data.new_country_name = $translate.instant('COUNTRY.' + $scope.data.new_country);
                }

                var templateSupport = $templateCache.get('jpb/contact-forms/mail-templates/ap-change.support.txt');
                var templateCustomer = $templateCache.get('jpb/contact-forms/mail-templates/ap-change.customer.txt');
                var templateSubject = $templateCache.get('jpb/contact-forms/mail-templates/ap-change.subject.txt');

                var textSupport = $interpolate(templateSupport)($scope.data);
                var textCustomer = $interpolate(templateCustomer)($scope.data);
                var subject = $interpolate(templateSubject)($scope.data).trim();
                SupportService.create(subject, textSupport, subject, textCustomer).then(function () {
                    close();
                    alertService.add('info', $translate('CONTACT.inquiry_success'));
                });
            }
        };

    });
