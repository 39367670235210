angular.module('quattro.jpb.model.account', [
    'quattro.core.model.prototype',
    'quattro.config',
])
    .factory('Account', function ($log, Model, account_type) {

        var prototype = Model.prototype;

        // clarify internal naming here
        function getDefaultData() {
            return {
                // primary key
                account: undefined,

                // customer data, who is it?
                address_name_vor: undefined,
                address_name_nach: undefined,
                birthday: undefined,
                address_str: undefined,
                address_plz: undefined,
                address_ort: undefined,
                address_land: undefined,
                address_tel: undefined,
                address_fax: undefined,
                address_mobil: undefined,
                firma: undefined,
                ustid: undefined,

                memo: undefined,
                admin_memo: undefined,
                password: undefined,

                mail: undefined,
                mail_forwards: [],
                mail_inboxsave: true,
                max_mailinglist: 5,

                vac_enabled: false,
                vac_subject: undefined,
                vac_text: undefined,
                vac_date_start: undefined,
                vac_date_end: undefined,

                // payment-invoicing-mess
                payment_monthly_fee: undefined,
                invoice_type: 'mail',    // invoice transport via e- or snail- mail
                payment_type: 'invoice',    // invoice or "iban"
                payment_address_mail: undefined,    // jpberlinRechnungsEmail
                bank_inhaber: undefined,
                bank_bank: '',  // mustn't be `undefined` w/ `account.add`
                bank_iban: undefined,
                bank_bic: undefined,
                payment_address_same: true,
                payment_address_name_vor: undefined,
                payment_address_name_nach: undefined,
                payment_address_firma: undefined,
                payment_address_str: undefined,
                payment_address_plz: undefined,
                payment_address_ort: undefined,
                payment_address_land: undefined,

                // av contract related
                av_contract_signed: undefined,
                av_contract_info_date: undefined,
                av_contract_accept_name: undefined,
                av_contract_info_version: undefined,

                // admin stuff
                status: undefined,
                type: account_type,
                end_date: undefined,
                otrs_cancel_number: undefined,
                uidnumber: undefined,
                gidnumber: undefined,
                pass_clear: undefined,
                fibunextdate: undefined,
                date_register: undefined,
                services: undefined,

                //alternate_mail: undefined,    // not used in jpb
            };
        }


        var authorization = {
            read: 'all',
            create: ['admin'],
            update: {
                default: ['admin', 'account'],
                attributes: {
                    pass_clear: ['admin'],
                    status: ['admin'],
                    date_register: ['admin'],
                    fibunextdate: ['admin'],
                    payment_monthly_fee: ['admin'],
                    end_date: ['admin'],
                    otrs_cancel_number: ['admin'],
                    uidnumber: ['admin'],
                    gidnumber: ['admin'],
                    admin_memo: ['admin'],
                    max_mailinglist: ['admin'],
                    memo: ['account'],
                }
            }
        };


        var scenarios = {
            read: [
                {
                    method: 'q.account.get',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                    },
                    results: {
                        anrede: null,
                        address_name_vor: null,
                        address_name_nach: null,
                        birthday: {
                            filter: prototype.filter.UTCToLocale,
                        },
                        address_str: null,
                        address_plz: null,
                        address_ort: null,
                        address_land: null,
                        address_tel: null,
                        address_fax: null,
                        address_mobil: null,
                        firma: 'address_firma',
                        ustid: null,

                        services: null,
                        max_mailinglist: null,
                        memo: null,
                        admin_memo: null,

                        type: null,
                        status: null,
                        payment_monthly_fee: {
                            filter: prototype.filter.strToNumber,
                        },
                        uidnumber: null,
                        gidnumber: null,
                        pass_clear: null,
                        fibunextdate: null,
                        date_register: null,

                        end_date: null,
                        otrs_cancel_number: null,
                        canceled: {
                            filter: function (x, result) {
                                return !!result.end_date;
                            },
                        },

                        alt_mail: null,
                        mail: null,
                        mail_forwards: null,
                        mail_inboxsave: null,

                        invoice_type: 'payment_invoice_type',
                        payment_type: null,
                        payment_address_mail: null,

                        bank_inhaber: null,
                        bank_bank: null,
                        bank_iban: null,
                        bank_bic: null,

                        av_contract_signed: 'av_contract.signed',
                        av_contract_info_date: 'av_contract.info.date',
                        av_contract_accept_name: 'av_contract.info.name',
                        av_contract_info_version: 'av_contract.info.version',

                        payment_address_same: null,
                        payment_address_name_vor: null,
                        payment_address_name_nach: null,
                        payment_address_firma: null,
                        payment_address_str: null,
                        payment_address_plz: null,
                        payment_address_ort: null,
                        payment_address_land: null,
                    }
                },
                {
                    method: 'q.mail.sieve.get',
                    params: {
                        'mail': {
                            primary_key: true,
                            filter: function (value, data, model) {
                                // get value from first command's result cache
                                return model && model.resultCache && model.resultCache.mail;
                            },
                        },
                    },
                    results: {
                        vac_enabled: null,
                        vac_subject: null,
                        vac_text: null,
                        vac_date_start: null,
                        vac_date_end: null,
                    },
                    delay_if: function () {
                        // always delay, parameter `mail` is only available after first command
                        return true;
                    },
                },
            ],
            create: [
                {
                    method: 'q.admin.account.add',
                    params: {
                        'account': {
                            required: true,
                            api_name: 'username'
                        },
                        'password': true,
                        'type': true,
                        'memo': null,
                        'max_mailinglist': null,
                        admin_memo: null,
                        'anrede': true,
                        'address_name_vor': {
                            required: true,
                            api_name: 'vorname'
                        },
                        'address_name_nach': {
                            required: true,
                            api_name: 'nachname'
                        },
                        'birthday': null,
                        'address_str': {
                            required: true,
                            api_name: 'strassenummer'
                        },
                        'address_plz': {
                            required: true,
                            api_name: 'plz'
                        },
                        'address_ort': {
                            required: true,
                            api_name: 'ort'
                        },
                        'address_land': {
                            required: true,
                            api_name: 'land',
                        },
                        'firma': {
                            undefined_if_empty: true,
                        },
                        'ustid': {
                            undefined_if_empty: true,
                        },
                        'address_tel': {
                            required: true,
                            api_name: 'tel'
                        },
                        'address_fax': {
                            api_name: 'fax',
                            undefined_if_empty: true,
                        },
                        'address_mobil': {
                            api_name: 'mobil',
                            undefined_if_empty: true,
                        },
                        'payment_monthly_fee': {
                            required: true,
                            api_name: 'monthly_fee',
                            filter: prototype.filter.strToNumber,
                        },
                        'netto': {
                            filter: prototype.filter.strToBoolean,
                        },

                        'invoice_type': true,
                        'payment_type': true,

                        fibunextdate: {
                            filter: prototype.filter.UTCToLocale,
                        },
                        date_register: {
                            filter: prototype.filter.UTCToLocale,
                        },

                        payment_address_mail: {
                            undefined_if_empty: true,
                        },

                        'bank_inhaber': null,
                        'bank_bank': {
                            always_send: true,  // always required by api
                        },
                        'bank_iban': null,
                        'bank_bic': null,

                        'payment_address_same': true,
                        'payment_address_name_vor': null,
                        'payment_address_name_nach': {
                            always_send: true,
                            required_if: function (data) {
                                return !data.payment_address_firma && !data.payment_address_same;
                            }
                        },
                        payment_address_firma: {
                            always_send: true,
                            required_if: function (data) {
                                return !data.payment_address_name_nach && !data.payment_address_same;
                            }
                        },
                        'payment_address_str': {
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        },
                        'payment_address_plz': {
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        },
                        'payment_address_ort': {
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        },
                        'payment_address_land': {
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        }
                    },
                }
            ],
            update: [
                {
                    method: 'q.account.payment.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'address_str': null,
                        'address_plz': null,
                        'address_ort': null,
                        'address_land': null,
                        'address_fax': null,
                        'address_mobil': null,
                        'address_tel': {
                            undefined_if_empty: true,
                        },
                        anrede: null,
                        'address_name_vor': null,
                        'address_name_nach': true,
                        birthday: {
                            filter: prototype.filter.UTCToLocale,
                        },
                        'firma': 'address_firma',
                        'ustid': null,
                        'invoice_type': true,
                        'payment_type': true,
                        payment_address_mail: null,
                        'bank_inhaber': {
                            undefined_if_empty: true,
                            required_if: function (data) {
                                return data.payment_type === 'dta';
                            }
                        },
                        'bank_bank': {
                            undefined_if_empty: true,
                        },
                        'bank_iban': {
                            undefined_if_empty: true,
                            required_if: function (data) {
                                return data.payment_type === 'dta';
                            }
                        },
                        'bank_bic': {
                            undefined_if_empty: true,
                            required_if: function (data) {
                                return data.payment_type === 'dta';
                            }
                        },
                        'payment_address_same': true,
                        'payment_address_name_vor': null,
                        'payment_address_name_nach': {
                            always_send: true,
                            required_if: function (data) {
                                return !data.payment_address_firma && !data.payment_address_same;
                            }
                        },
                        payment_address_firma: {
                            always_send: true,
                            required_if: function (data) {
                                return !data.payment_address_name_nach && !data.payment_address_same;
                            }
                        },
                        'payment_address_str': {
                            undefined_if_empty: true,
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        },
                        'payment_address_plz': {
                            undefined_if_empty: true,
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        },
                        'payment_address_ort': {
                            undefined_if_empty: true,
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        },
                        'payment_address_land': {
                            required_if: function (data) {
                                return !data.payment_address_same;
                            }
                        }
                    },
                },
                {
                    method: 'q.account.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'status': null,
                        //'type': null,
                        'pass_clear': {
                            undefined_if_empty: true,
                        },
                        'payment_monthly_fee': {
                            api_name: 'monthly_fee',
                            filter: prototype.filter.strToNumber,
                        },
                        'end_date': {
                            required_if: function (data) {
                                return data.canceled;
                            },
                            always_send: true,
                            filter: function (value, data) {
                                return data.canceled ? prototype.filter.UTCToLocale(value) : '';
                            }
                        },
                        'otrs_cancel_number': {
                            required_if: function (data) {
                                return data.canceled;
                            },
                            always_send: true,
                            filter: function (value, data) {
                                return data.canceled ? value : '';
                            }
                        },
                        'uidnumber': {
                            filter: prototype.filter.strToInt,
                        },
                        'gidnumber': {
                            filter: prototype.filter.strToInt,
                        },
                        fibunextdate: {
                            filter: prototype.filter.UTCToLocale,
                        },
                        date_register: {
                            filter: prototype.filter.UTCToLocale,
                        },
                        alt_mail: null,
                    },
                },
                {
                    method: 'q.account.memo.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'memo': null,
                    },
                },
                {
                    method: 'q.admin.account.memo.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'admin_memo': {
                            api_name: 'memo',
                        },
                    },
                },
                {
                    method: 'q.admin.account.mailinglist.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'max_mailinglist': null
                    },
                },
                {
                    method: 'q.admin.account.services.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'services': {
                            api_name: 'services',
                        },
                    },
                },
                {
                    method: 'q.mail.inboxsave.set',
                    params: {
                        mail: {
                            primary_key: true,
                        },
                        mail_inboxsave: {
                            api_name: 'inboxsave',
                        },
                    },
                    delay_if: function (data) {
                        // send this command last when `false`, prevent runtime w/ forwards
                        return data.mail_inboxsave === false;
                    },
                },
                {
                    method: 'q.mail.forward.set',
                    params: {
                        mail: {
                            primary_key: true,
                        },
                        mail_forwards: {
                            api_name: 'forwards',
                        },
                        mail_forwards_item: {
                            api_name: 'forwards',
                            validate_only: true,
                            undefined_if_empty: true,
                            filter: function (x) {
                                return [x];
                            }
                        }
                    },
                    delay_if: function (data) {
                        // send this command last when list empty, prevent runtime w/ inboxsave
                        return data.mail_forwards.length === 0;
                    },
                },
                {
                    method: 'q.mail.sieve.set',
                    params: {
                        'mail': {
                            primary_key: true,
                        },
                        'vac_enabled': true,
                        'vac_subject': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                        },
                        'vac_text': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            required_if: function (data) {
                                return data.vac_enabled;
                            }
                        },
                        'vac_date_start': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            filter: prototype.filter.UTCToLocale,
                            undefined_if_empty: true
                        },
                        'vac_date_end': {
                            always_send: true,  // fix missing api json PATCH, VQ-505
                            filter: prototype.filter.UTCToLocale,
                            undefined_if_empty: true
                        },
                    },
                },
                {
                    method: 'q.account.password.set',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'password': {
                            undefined_if_empty: true,
                        },
                    },
                    delay_if: function () {
                        // always delay
                        return true;
                    },
                },
                {
                    method: 'q.account.dsgvo.save',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'av_contract_accept_name': {
                            api_name: 'name',
                            required: true
                        },
                        'av_contract_info_version': {
                            api_name: 'version',
                        },
                    },
                },
            ],
        };


        function Account(config, scenario) {
            this.scenario = scenario;
            var data = scenario === 'create' ? getDefaultData() : {};
            this.data = angular.merge(data, config);
            this.data.$validate = this.validate.bind(this);
            this.data.$isDirty = this.isDirty.bind(this);
        }

        Account.prototype = angular.merge(new Model(), {
            scenarios: scenarios,
            authorization: authorization,
        });

        return Account;
    });
