angular
    .module('quattro.domain.modal_add', [
        'quattro.model',
        'quattro.domain.form',
        'quattro.core.alert.service',
        'quattro.core.myModal',
    ])
    .run(['$rootScope', 'Domain', 'myModalService', function ($rootScope, Domain, myModalService) {

        $rootScope.$on('vq.domain.add', function (event, domain, callback) {

            var model = new Domain(domain, 'create');

            myModalService.showModal({
                templateUrl: 'jpb/domain/modal_add.html',
                controller: 'ModalAddDomainController',
                inputs: {
                    model: model,
                }
            }).then(function (modal) {
                modal.close.then(function () {
                    callback && callback();
                });
            });
        });
    }])
    .controller('ModalAddDomainController', function ($scope, close, model, alertService, domain_types) {

        $scope.data = model.data;

        model.data.mailhost = 'smtp:[plasma.jpberlin.de]:10026';
        model.data.type = domain_types[0];


        $scope.cancel = close;

        $scope.save = function () {

            var promise = model.create();
            promise.then(function () {
                alertService.add('success', 'Domain wurde angelegt.');
                close({
                    model: model,
                });
            }, function () {
                alertService.add('error', 'Domain konnte nicht angelegt werden.');
            });
        };
    });
