// deprecated: before bmbo this was app-module, still used by tests. ref VQ-554
angular.module('quattro', ['quattro.jpb']);


// Declare app level module which depends on views, and components
angular.module('quattro.jpb', [
    'ngRoute',
    'ui.date',
    'templates-app_core',
    'templates-app_jpb',
    'ngCsv',
    'quattro.config',
    'quattro.index',
    'quattro.model',
    'quattro.account',
    'quattro.forward',
    'quattro.domain',
    'quattro.mailinglist',
    'quattro.email',
    'quattro.ftp',
    'quattro.login',
    'quattro.logout',
    'quattro.404',
    'quattro.jpb.login.password-reset',
    'quattro.jpb.login.password-set',
    'quattro.directives',
    'quattro.search',
    'pascalprecht.translate',

    'quattro.core.filters',
    'quattro.core.hpls_api_1',
    'quattro.core.alert',
    'quattro.core.crumbs',
    'quattro.core.user',
    'quattro.core.form-helper.checkval',

    'quattro.jpb.search',
    'quattro.jpb.dashboard',
    'quattro.jpb.incident.edit',
])
    .config(function ($routeProvider, $translateProvider) {

        // custom route object extensions:
        // - `auth`
        // - `bodyClass`: class name for body tag
        // - `crumbClass`: class name for breadcrumb container

        $routeProvider
            .when("/", {
                template: "loading...",
                controller: "IndexController"
            })

            .when("/dashboard", {
                templateUrl: "jpb/site/dashboard.html",
                controller: "DashboardController",
                controllerAs: 'vm',
                bodyClass: 'dashboard',
                crumbClass: 'dashboard',
            })

            .when("/incident/edit", {
                templateUrl: "jpb/incident/edit.html",
                controller: "EditIncidentController",
                controllerAs: 'vm',
                bodyClass: 'incident',
                crumbClass: 'incident',
                auth: function (user, userlevel) {
                    return user && userlevel === 'admin';
                }
            })

            .when("/account/create", {
                templateUrl: "jpb/account/create.html",
                controller: "CreateAccountCtrl",
                auth: function (user, userlevel) {
                    return user && userlevel == 'admin';
                }
            })
            .when("/account=:account", {
                templateUrl: "jpb/account/view.html",
                controller: "AccountCtrl",
                crumbClass: 'account',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account');
                }
            })
            .when("/account=:account/edit/:section?", {
                templateUrl: "jpb/account/edit.html",
                controller: "EditAccountCtrl",
                crumbClass: 'account edit',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account');
                }
            })

            .when("/account=:account/mailinglist=:mailinglist", {
                templateUrl: "jpb/mailinglist/view.html",
                controller: "MailinglistCtrl",
                crumbClass: 'mailinglist',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'mailinglist');
                }
            })
            .when("/account=:account/mailinglist=:mailinglist/edit/:section?", {
                templateUrl: "jpb/mailinglist/edit.html",
                controller: "EditMailinglistCtrl",
                crumbClass: 'mailinglist edit',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'mailinglist');
                }
            })

            .when("/domain=:domain", {
                templateUrl: "jpb/domain/view.html",
                controller: "DomainCtrl",
                crumbClass: 'domain',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain');
                }
            })
            .when("/domain=:domain/edit/:section?", {
                templateUrl: "jpb/domain/edit.html",
                controller: "EditDomainCtrl",
                crumbClass: 'domain edit',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain');
                }
            })

            .when("/domain=:domain/email=:email?", {
                templateUrl: "jpb/email/view.html",
                controller: "EmailCtrl",
                crumbClass: 'email',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain' || userlevel == 'mail');
                }
            })
            .when("/domain=:domain/email=:email?/edit", {
                templateUrl: "jpb/email/edit.html",
                controller: "EditEmailCtrl",
                crumbClass: 'email edit',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain' || userlevel == 'mail');
                }
            })

            .when("/domain=:domain/ftp=:ftp", {
                templateUrl: "jpb/ftp/view.html",
                controller: "FtpCtrl",
                crumbClass: 'ftp',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain' || userlevel == 'ftp');
                }
            })
            .when("/domain=:domain/ftp=:ftp/edit", {
                templateUrl: "jpb/ftp/edit.html",
                controller: "EditFtpCtrl",
                crumbClass: 'ftp edit',
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain' || userlevel == 'ftp');
                }
            })


            .when('/login', {
                templateUrl: 'jpb/site/login.html',
                controller: 'LoginCtrl'
            })
            .when("/logout", {
                template: 'logging out...',
                controller: "LogoutCtrl"
            })
            .when('/password/reset', {
                templateUrl: 'jpb/password/password-reset.html',
                controller: 'JpbPasswordResetController',
                controllerAs: 'vm',
            })
            .when('/password/set/:token?', {
                templateUrl: 'jpb/password/password-set.html',
                controller: 'JpbPasswordSetController',
                controllerAs: 'vm',
            })

            .when("/search/:level/:user?/:query", {
                templateUrl: "jpb/site/search.html",
                controller: "SearchCtrl",
                bodyClass: 'route-search',
                reloadOnSearch: false,
                auth: function (user, userlevel) {
                    return user && (userlevel == 'admin' || userlevel == 'account' || userlevel == 'domain');
                }
            })
            .when('/404', {
                templateUrl: 'jpb/site/404.html',
                controller: '404Ctrl'
            })
            .otherwise({
                redirectTo: function (params, path, search) {
                    // old url schmeme used '/' instead of '=', try to fallback
                    var newPath = path
                        .replace(/\/account\//, '/account=')
                        .replace(/\/mailinglist\//, '/mailinglist=')
                        .replace(/\/domain\//, '/domain=')
                        .replace(/\/email\//, '/email=')
                        .replace(/\/ftp\//, '/ftp=');
                    return (path === newPath) ? '/404' : newPath;
                }
            });

        $translateProvider.useStaticFilesLoader({
            prefix: 'lang/jpb-',
            suffix: '.json'
        });
        $translateProvider.preferredLanguage('de_DE');
        $translateProvider.useSanitizeValueStrategy('escapeParameters');
    })
    .config(['uiDateConfig', function (uiDateConfig) {
        uiDateConfig.changeYear = true;
        uiDateConfig.changeMonth = true;
        // format which will be displayed in gui. element's `ui-date-format`
        // will decide format which will be set on ng-model.
        uiDateConfig.dateFormat = "yy-mm-dd";
    }])
    .run(['$anchorScroll', function ($anchorScroll) {
        $anchorScroll.yOffset = angular.element('header[id=header]');
    }])
    .config(['apiProvider', 'api_url', function (apiProvider, api_url) {
        apiProvider.id = 'quattro';
        apiProvider.url = api_url;
    }])
    .run(function ($rootScope, $location, userService, crumbService) {

        $rootScope.$on("$routeChangeStart", function (event, next) {

            var nextRoute = next && next.$$route;

            if (!nextRoute) {
                return; // early exit
            }

            // check authentication
            var auth = nextRoute.auth;
            var userName = userService.get('userName');
            var userLevel = userService.get('userLevel');
            if (auth && !auth(userName, userLevel)) {
                $location.path('/login');
            }
        });

        $rootScope.$on("$routeChangeSuccess", function (event, next) {

            var nextRoute = next.$$route || {};
            var params = next.params;

            var bodyClass = nextRoute.bodyClass || '';
            var crumbClass = nextRoute.crumbClass || '';

            $rootScope.bodyClass = bodyClass;

            // set breadcrumb w/ url route data
            crumbService.setRouteParams(params, crumbClass);
        });


        $rootScope.crumbs = crumbService.scope;

        $rootScope.user = userService.scope;

        $rootScope.searchInput = '';

    })
    .controller('MainCtrl', function ($scope, $translate, api, searchService, userService) {

        $scope.api = api.scope;
        $scope.searchService = searchService.scope;

        $scope.switchLanguage = function (key) {
            $translate.use(key);
        };

        // validate user session
        api
            .single({method: 'q.session.get', params: {}})
            .then(null, userService.sessionExpired);
    });
