angular.module('quattro.jpb.model.domain', [
    'quattro.core.model.prototype',
])
    .factory('Domain', function ($log, api, Model) {

        var prototype = Model.prototype;

        // clarify internal naming here
        function getDefaultData() {
            return {
                // primary key
                account: undefined,
                domain: undefined,

                type: undefined,
                domaintype_change_blacklisted: undefined,
                letsencrypt: undefined,
                http_tls_ca: undefined,
                http_tls_cert: undefined,
                dkim_dns_record_name: undefined,
                dkim_private_key: undefined,
                config: undefined,
                shadowcopy: undefined,
                monthly_fee: undefined,
                registered_at: undefined,
                memo: undefined,
                admin_memo: undefined,
                password: undefined,

                // admin stuff
                status: undefined,
                end_date: undefined,
                otrs_cancel_number: undefined,
                fibunextdate: undefined,
                uidnumber: undefined,
                gidnumber: undefined,
                homedir: undefined,
                mailhost: undefined,
                pass_clear: undefined,
            };
        }


        var authorization = {
            read: 'all',
            create: ['admin'],
            update: {
                default: ['admin', 'account', 'domain'],
                attributes: {
                    pass_clear: ['admin'],
                    status: ['admin'],
                    registered: [],
                    type: ['account', 'admin'],
                    monthly_fee: ['admin'],
                    config: ['admin'],
                    homedir: ['admin'],
                    end_date: ['admin'],
                    otrs_cancel_number: ['admin'],
                    fibunextdate: ['admin'],
                    uidnumber: ['admin'],
                    gidnumber: ['admin'],
                    mailhost: ['admin'],
                    admin_memo: ['admin'],
                    memo: ['account', 'domain'],
                }
            }
        };


        var scenarios = {
            read: [
                {
                    method: 'q.domain.get',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                    },
                    results: {
                        'type': null,
                        'mailhost': 'mailserver',
                        'memo': null,
                        admin_memo: null,
                        'config': null,
                        http_tls_ca: null,
                        http_tls_cert: null,
                        dkim_dns_record_name: null,
                        dkim_private_key: null,
                        'shadowcopy': null,
                        'status': null,
                        domain_flags: null,
                        'monthly_fee': {
                            filter: prototype.filter.strToNumber,
                        },
                        'registered_at': 'date_register',
                        'pass_clear': null,
                        fibunextdate: null,
                        'uidnumber': null,
                        'gidnumber': null,
                        'homedir': null,
                        letsencrypt: null,
                        domaintype_change_blacklisted: {
                            filter: prototype.filter.strToBoolean,
                        },

                        end_date: null,
                        otrs_cancel_number: null,
                        canceled: {
                            filter: function (x, result) {
                                return !!result.end_date;
                            },
                        },
                    }
                }
            ],
            create: [
                {
                    method: 'q.account.domain.add',
                    params: {
                        'account': {
                            primary_key: true,
                        },
                        'domain': true,
                        'password': true,
                        'mailhost': true,
                        'type': true,
                        letsencrypt: null,
                        fibunextdate: {
                            undefined_if_empty: true,
                            filter: prototype.filter.UTCToLocale,
                        },
                        'monthly_fee': {
                            filter: prototype.filter.strToNumber,
                        },
                        'memo': null,
                        admin_memo: null,
                        'config': null,
                        http_tls_ca: null,
                        http_tls_cert: null,
                        dkim_dns_record_name: null,
                        dkim_private_key: null,
                    },
                }
            ],
            update: [
                {
                    method: 'q.domain.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        fibunextdate: {
                            undefined_if_empty: true,
                            filter: prototype.filter.UTCToLocale,
                        },
                        'monthly_fee': {
                            filter: prototype.filter.strToNumber,
                        },
                        'type': null,
                        'status': null,
                        'pass_clear': {
                            undefined_if_empty: true,
                        },
                        'uidnumber': {
                            filter: prototype.filter.strToInt,
                        },
                        'gidnumber': {
                            filter: prototype.filter.strToInt,
                        },
                        'homedir': null,
                        letsencrypt: null,
                        http_tls_ca: null,
                        http_tls_cert: null,
                        dkim_dns_record_name: {
                            required_if: function (data) {
                                return !!data.dkim_private_key;
                            }
                        },
                        dkim_private_key: {
                            required_if: function (data) {
                                return !!data.dkim_dns_record_name;
                            }
                        },
                        end_date: {
                            required_if: function (data) {
                                return data.canceled;
                            },
                            always_send: true,
                            filter: function (value, data) {
                                return data.canceled ? prototype.filter.UTCToLocale(value) : '';
                            }
                        },
                        otrs_cancel_number: {
                            required_if: function (data) {
                                return data.canceled;
                            },
                            always_send: true,
                            filter: function (value, data) {
                                return data.canceled ? value : '';
                            }
                        },
                    },
                },
                {
                    method: 'q.domain.memo.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'memo': null,
                    },
                },
                {
                    method: 'q.admin.domain.memo.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'admin_memo': {
                            api_name: 'memo',
                        },
                    },
                },
                {
                    method: 'q.domain.mailserver.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'mailhost': {
                            api_name: 'host'
                        },
                    },
                },
                {
                    method: 'q.domain.config.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'config': null,
                    },
                },
                {
                    method: 'q.domain.password.set',
                    params: {
                        'domain': {
                            primary_key: true,
                        },
                        'password': {
                            undefined_if_empty: true,
                        },
                    },
                },
            ],
        };


        function query(account) {
            var promise = api.single('q.account.domain.list', {
                account: account,
            });
            promise.then(null, function (error) {
                $log.error('q.account.domain.list', error.code, error.message);
            });
            return promise;
        }


        function Domain(config, scenario) {
            this.scenario = scenario;
            var data = scenario === 'create' ? getDefaultData() : {};
            this.data = angular.merge(data, config);
            this.data.$validate = this.validate.bind(this);
        }

        // static
        Domain.query = query;

        Domain.prototype = angular.merge(new Model(), {
            scenarios: scenarios,
            authorization: authorization,
        });

        return Domain;
    });
